import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
// import { DataTablesModule } from "angular-datatables";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { RowDataComponent } from './rowdata/rowdata.component';
import { AddDataFileComponent } from './rowdata/add-file.component';
import { UserComponent } from './user/user.component';
import { LogsComponent } from './user/logs.component';
import { AddEditComponent } from './user/add-edit.component';
// import { RoleComponent } from './role/role.component'
// import { RoleAddEditComponent } from './role/add-edit.component'
import { CompetitionsComponent } from './competitions/competitions.component';
import { PlayerComponent } from './player/player.component';
import { AppService, ExcelService } from './_services';
import { HighchartsChartModule } from 'highcharts-angular';
import { PlayerReportsComponent } from './player/reports/report.component';
import { GetReportsComponent } from './reports/report.component';
import { TeamReportsComponent } from './team/report.component';
import { CategoryPipe, RemovewhitespacesPipe } from './category.pipe';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { AssignCompetitionsComponent } from './assign-competitions/assign-competitions.component';
import { AgGridModule } from 'ag-grid-angular';
import { DownloadComponent } from './download/download.component';
// import { DpDatePickerModule } from 'ng2-date-picker';
import { DatepickerModule } from 'ng2-datepicker';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { RemovespacePipe } from './removespace.pipe';
import { ProgressBarComponent } from './player/reports/progress-bar/progress-bar.component';
import { ProgressBarRendererComponent } from './player/reports/progress-bar-renderer/progress-bar-renderer.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CapitalizeWordsPipe } from './_helpers/capitalize-words.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		// DataTablesModule,
		// DpDatePickerModule,
		NgxSliderModule,
		DatepickerModule,
		NgxPaginationModule,
		FormsModule,
		NgbModule,
		NgxCsvParserModule,
		NgbNavModule,
		NgSelectModule,
		HighchartsChartModule,
		AgGridModule.withComponents([]),
		AgGridModule.withComponents([ProgressBarRendererComponent]),
		MatSlideToggleModule,
		CommonModule

	],
	declarations: [
		// CategoryPipe,
		RemovewhitespacesPipe,
		AppComponent,
		AlertComponent,
		HomeComponent,
		LoginComponent,
		RowDataComponent,
		CompetitionsComponent,
		AddDataFileComponent,
		UserComponent,
		LogsComponent,
		AddEditComponent,
		// RoleComponent,
		// RoleAddEditComponent,
		// RoleAddEditComponent,
		PlayerComponent,
		PlayerReportsComponent,
		GetReportsComponent,
		TeamReportsComponent,
		AssignCompetitionsComponent,
		DownloadComponent,
		RemovespacePipe,
		ProgressBarComponent,
		ProgressBarRendererComponent,
		CapitalizeWordsPipe,
	],
	providers: [
		AppService,
		ExcelService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

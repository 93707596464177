import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {
	AccountService,
	AlertService,
	ChartService,
	FileService,
} from '@app/_services';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
	templateUrl: 'player.component.html',
	styleUrls: ['player.component.scss'],
})
export class PlayerComponent implements OnInit {
	form: FormGroup;
	playersData = null;

	p: number;
	itemsPerPage: any;
	totalItems: any;
	items: any;
	players: any;

	filters: any;
	groupName: any;
	filternewname: any;
	viewplayer: any;
	compname: any;
	season: any;

	NewData: any;
	NewDataAVG: any;
	Oponent: any;
	reports: any;
	constructor(
		private formBuilder: FormBuilder,
		private accountService: AccountService,
		private route: ActivatedRoute,
		private router: Router,
		private service: FileService,
		private alertService: AlertService,
		private http: HttpClient,
		private ChartService: ChartService
	) {
		this.route.params.subscribe(
			(params) => (this.playersData = params.Players)
		);
	}

	compList: any;
	ngOnInit(): void {
		this.filternewname = this.route.snapshot.params['name'].replace(
			/-/g,
			' '
		);
		//Table Filters
		this.accountService
			.getPlayersFilter('')
			.pipe(first())
			.subscribe((filters) => {
				this.filters = filters;
				console.log(filters);
				// this.compname= this.filters.data.Competition[0].value
				// this.season=this.filters.data.Season[0].value;

				this.getPlayerData();
			});
		//LOAD TABLE DATA
	}
	getPlayerData() {
		this.http
			.get(
				`${environment.apiUrl}/calculate/player?page=${1}
		&competition_name=${this.compname}&season=${this.season}
		&limit=&player=${this.filternewname}`
			)
			.subscribe((data: any) => {
				this.players = data;
				console.log(this.players);
			});
	}

	getreport() {
		this.http
			.get(
				`${environment.apiUrl}/calculate/player?page=${1}
		&competition_name=${this.compname.value}&season=${this.season.value}
		&limit=&player=${this.filternewname}`
			)
			.subscribe((data: any) => {
				this.players = data;
			});
	}

	returnZero() {
		return 0;
	}

	getReport(competitionName, season) {
		this.NewData = [];
		this.NewDataAVG = [];
		this.Oponent = [];
		this.http
			.get(
				`${environment.apiUrl}/report/player?page=${1}&limit=${
					this.itemsPerPage
				}
        &player=${
			this.filternewname
		}&competition_name=${competitionName}&season=${season}
        `
			)
			.subscribe((data: any) => {
				this.reports = data;
				console.log(this.reports);
				data.data.forEach((element) => {
					this.NewData.push({
						y: element.CVG,
						Result: element.Result,
					});
					this.NewDataAVG.push({
						y: element['AVG-'],
						Result: element.Result,
					});
					this.Oponent.push(element.Oponent);
				});
				this.ChartService.CreateCharts(
					this.Oponent,
					this.NewData,
					this.NewDataAVG
				);
			});
	}
}

<div class="container-login100">
    <div class="auth">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="login100-form">
            <span class="logo-login"><img src="assets/cvr-logo.png"></span>
            <h6>Login To Your Account</h6>
            <div class="form-group mt-3 wrap-input100">
                <label for="username"><i class="far fa-user"></i> Username </label>
                <input type="text" formControlName="username" class="input100" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
            </div>
            <div class="form-group mt-3 wrap-input100">
                <label for="password"><i class="fas fa-key"></i> Password</label>
                <input type="password" formControlName="password" class="input100" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
            </div>
            <div class="form-group  mt-3">
                <button [disabled]="loading" class="login100-form-btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                <!-- <a routerLink="../register" class="btn btn-link">Register</a> -->
            </div>
        </form>
    </div>

</div>
<div class="row justify-content-center align-items-center bg2">
    <div class="col-sm-6">

        <h5 *ngIf="isAddMode" class="mt-2">Add User</h5>
        <h5 *ngIf="!isAddMode" class="mt-2">Edit User</h5>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row">
                <div class="form-group col">
                    <label for="firstname">First Name</label>
                    <input type="text" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                    <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                        <div *ngIf="f.firstname.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label for="lastname">Last Name</label>
                    <input type="text" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                    <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                        <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group col">
                    <label for="email">Email</label>
                    <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Password is required</div>
                        <div *ngIf="f.email.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <div class="form-group col">
                    <span *ngIf="userroles">
                    <label for="roles">Role</label>
                    <select formControlName="roles" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.roles.errors }">
                        <option >Select Role</option>
                        <option *ngFor="let userrole of userroles.data" value="{{userrole._id}}">{{userrole.name}}</option>
                    </select>
                    </span>
                </div>

            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.role.errors.required">Password is required</div>
                <div *ngIf="f.role.errors.minlength">Password must be at least 6 characters</div>
            </div>
            <div class="form-group">
                <label for="password">Password
                    <em *ngIf="!isAddMode">(Leave blank to keep the same password)</em>
                </label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
            </div>

            <div class="form-group">
                <button [disabled]="loading" class="btn-theme mr-2">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Save
            </button>
                    <a routerLink="/users" class="btn-cancel">Cancel</a>
                </div>
        </form>

    </div>
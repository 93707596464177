import { Component, OnInit } from '@angular/core';

import { first } from 'rxjs/operators';
import { User } from '@app/_models';
import { AccountService } from '@app/_services';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home',
     templateUrl: 'home.component.html',
     styleUrls: ['home.component.scss']
     })
export class HomeComponent {
    user: User;
    usercount: any;
    users= null;
    competitions:any
    players:any;
    playerName: any;
    pl:any;
    showPlayers:any
    ViewPlayer:any
    teams:any
    // alphabets = [
    //     'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
    // ]
    constructor(
        private accountService: AccountService,
        private router: Router,
        private http: HttpClient) {
        this.user = this.accountService.userValue;
    }


    ngOnInit() {
        // this.showPlayers = 'A';
        this.accountService.getAll().pipe(first()).subscribe(users => this.users = users);
        
        this.accountService.getCompetitions().pipe(first()).subscribe(competitions => {
            this.competitions = competitions
            // console.log(this.competitions)
        })

        this.accountService.getPlayersNames().pipe(first()).subscribe(playerName =>{this.playerName = playerName});
        this.accountService.getTeamsNames().pipe(first()).subscribe(teams =>{this.teams = teams});
        
    }

    showPlayerList(){
        $('.classaplhabet').css('display' , 'none')
        if(this.showPlayers){
            $('.'+this.showPlayers).css('display' , 'flex')
        }else{
        }
    }

    ViewPlayerList(){
        if(this.ViewPlayer != undefined) {
            var newurl = this.ViewPlayer.replace(/ /g, '-')
                this.router.navigate(['/player/'+newurl])
        }
        
    }

}
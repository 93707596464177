import { Component, OnInit } from '@angular/core';
import { AccountService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-logs',
	templateUrl: './logs.component.html',
	styleUrls: ['./user.component.scss'],
})
export class LogsComponent implements OnInit {
	logs: any;

	constructor(
		private accountService: AccountService,
		private alertService: AlertService
	) {}

	ngOnInit() {
		this.accountService
			.getUserLogs()
			.pipe(first())
			.subscribe((logs) => {
				this.logs = logs;
				console.log(this.logs);
			});
	}
}

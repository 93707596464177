<div class="row justify-content-center align-items-center bg2">
	<div class="col-sm-6">
		<div class="col mb-4">
			<h5>Upload File</h5>
		</div>
		<form [formGroup]="saveFileForm" (ngSubmit)="onExpSubmit()">
			<div class="form_div">
				<input
					type="file"
					formControlName="file"
					#resumeInput
					required
					multiple
				/>
				<div class="select_wrapper">
					<label for="tournament">Choose a tournament:</label>
					<select
						formControlName="tournament"
						(change)="onChange($event)"
					>
						<option
							*ngFor="let tournament of tournaments"
							[ngValue]="tournament"
						>
							{{ tournament }}
						</option>
					</select>
				</div>
			</div>
			<br /><br />
			<p><em>Please Upload CSV file</em></p>
			<button type="submit" class="button" class="btn-theme mt-4">
				Upload File
				<span
					*ngIf="loading"
					class="spinner-border spinner-border-sm mr-1"
				></span>
			</button>
		</form>
	</div>
</div>

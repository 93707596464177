<!-- nav -->
<header *ngIf="user">
	<div>
		<a routerLink="/" class="site-logo"
			><img src="assets/cvr-logo.png" alt=""
		/></a>
	</div>
	<div class="navigation">
		<div>
			<a
				routerLink="/rowdata/add"
				class="add-btn"
				*ngIf="user.roles.name === 'admin'"
			>
				<!-- <img src="assets/add.svg">  -->
				Upload Data
			</a>
			<a
				class="nav-item nav-link"
				routerLink="/"
				routerLinkActive="active"
				>Home</a
			>
			<a
				class="nav-item nav-link"
				routerLink="/download"
				routerLinkActive="active"
				*ngIf="user.roles.name === 'admin'"
				>Downloads</a
			>
			<a
				class="nav-item nav-link"
				routerLink="/user"
				routerLinkActive="active"
				*ngIf="user.roles.name === 'admin'"
				>Users</a
			>
			<a
				class="nav-item nav-link"
				routerLink="/assign-competitions"
				routerLinkActive="active"
				*ngIf="user.roles.name === 'admin'"
				>Assign Competitions</a
			>
			<!-- <a class="nav-item nav-link" routerLink="/role" routerLinkActive="active" *ngIf="user.roles.name === 'admin'">Roles</a> -->
			<!-- <a class="nav-item nav-link" routerLink="/player/reports" routerLinkActive="active">Player Reports</a> -->
			<a
				class="nav-item nav-link"
				routerLink="/reports"
				routerLinkActive="active"
				>Reports</a
			>
			<a
				class="nav-item nav-link"
				routerLink="/rowdata"
				routerLinkActive="active"
				*ngIf="user.roles.name === 'admin'"
				>Row Data</a
			>
			<a href="#" (click)="logout()">
				<svg width="22" viewBox="0 0 26.805 26.805">
					<g transform="translate(0 0.001)">
						<path
							d="M22.866,3.938a13.385,13.385,0,1,0,0,18.929,13.341,13.341,0,0,0,0-18.929ZM12.617,7.121a.785.785,0,1,1,1.57,0v4.187a.785.785,0,0,1-1.57,0ZM13.4,19.212A5.81,5.81,0,0,1,9.579,9.027a.785.785,0,1,1,1.034,1.182,4.24,4.24,0,1,0,5.6.021.785.785,0,1,1,1.042-1.175A5.81,5.81,0,0,1,13.4,19.212Z"
							fill="#fff"
						></path>
					</g>
				</svg>
				Logout
			</a>
		</div>
	</div>
</header>

<!-- main app container -->
<div class="app-container">
	<alert></alert>
	<router-outlet></router-outlet>
</div>

<!-- <div class="version" *ngIf="version">
    App Version -  {{version.data.API_Version}}
</div> -->

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { findIndex, first } from 'rxjs/operators';
import { AccountService, AlertService, CSVService, FileService } from '@app/_services';
import { Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { event } from 'jquery';
import { trigger } from '@angular/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemovewhitespacesPipe } from '../category.pipe';
import { Router } from '@angular/router';
	
@Injectable()
@Component({
  selector: 'app-rowdata',
  templateUrl: './rowdata.component.html',
  styleUrls: ['./rowdata.component.scss'],
  providers:[RemovewhitespacesPipe]
})
export class RowDataComponent implements OnInit {
	user=JSON.parse(localStorage.getItem('sportsuser'))
	
	searchText:any;
	p: number;
	itemsPerPage:any;
	totalItems: any;
	items:any;
	players:any;
	
	filters:any;
	groupName:any
	myCheckBox:any;
	selection = [];
	flname:any;
	filname:any;
	filtername:any;
	viewplayer:any;
	playerName:any;
	FindPlayer:any;
	filterClass='hide';
	
	btntext='Show Filter'
	findCVG:any;
	competitions:any;
	tablehead:any;

	rowdata =[
		{id: 1, name: "10"},
		{id: 2, name: "20"},
		{id: 3, name: "50"},
		{id: 4, name: "100"},
		{id: 5, name: "200"},
		{id: 5, name: "500"},
		{id: 5, name: "1000"}
	];
	categoris:any;
	sortorder = false; 
	ASC:number;
	asc: boolean =false;
	dsc: boolean =false;
	sorting: any;
	sortingname='CVG';

	competitionName:any;
	round:any;
	season:any;
	position:any;
	sourceteam:any;
	competitionsData:any;
	compName:any;
	constructor(
		private accountService: AccountService, 
		private service: FileService,
		private alertService: AlertService,
		private http: HttpClient,
		private CSV: CSVService,
		private router:Router,
        private modalService: NgbModal,
	) {
		}
		
	ngOnInit(): void {
		// table headers
		
		this.itemsPerPage = '100';	
		this.accountService.getCompetitions().pipe(first()).subscribe(competitionsData => {
			this.competitionsData = competitionsData
			console.log(this.competitionsData)
			
		})
		
		if(localStorage.getItem('class') != null && localStorage.getItem('class') != undefined){
			var colmvis = JSON.parse(localStorage.getItem('class'))
		}else{
			colmvis = [];
		}
		$(document).on('click' , '.column-option button' , function(){
			this.thead = $(this).attr('data-name')
			this.cln =  document.getElementsByClassName(this.thead)
			if($('td , th').hasClass(this.thead)){
				$(document).find(this.cln).toggleClass('tdinactive')
				$(this).toggleClass('active')
			}else{
				$(document).find(this.cln).removeClass('tdinactive');
				$(this).removeClass('active')
			}

			if(!colmvis.includes(this.thead)){          //checking weather array contain the id
				colmvis.push(this.thead);               //adding to array because value doesnt exists
			}else{
				colmvis.splice(colmvis.indexOf(this.thead), 1);  //deleting
			}
			localStorage.setItem('class' ,JSON.stringify(colmvis))
			
		})

		// Competitions List
		this.accountService.getCompetitions().subscribe(competitions => {this.competitions = competitions})
		//LOAD TABLE DATA
		
		// this.search(1, this.sortingname);
		
		// FILTERS
		this.accountService.getPlayersFilter('').pipe(first())
		.subscribe(filters =>{this.filters = filters})
		
		$(document).on('change' , '.chkall' , function(){
			if($(this).is(':checked')){
				$(this).parents('h6').nextAll('label').find('input').prop('checked',true)
			}else{
				$(this).parents('h6').nextAll('label').find('input').prop('checked',false)
			}
		})
		
		$(document).on('change' ,'.flname' , function(){
			$(this).parents('.filters').find('h6').find('input').prop('checked',false)
		})
	}	

	
	getCompetitionName(name){
		this.compName = name;
		console.log(name)
		this.http.get(`${environment.apiUrl}/player?page=1&limit=1`,).pipe(first())
		.subscribe((data: any)  => {
			this.tablehead =  data;
		})
		this.search(1, this.sortingname, this.compName);

	}
	getteamreport(index){
		Object.entries(this.players.data[index]).map(data=>{
			data.map(nd=>{
				if(nd ==='Competition Name'){
					this.competitionName = data[1]
				}
				if(nd ==='Season'){
					this.season = data[1]
				}
				if(nd ==='Source Team'){
					this.sourceteam = data[1]
				}
			})
		})
		this.router.navigate([`team/${this.competitionName.replace(/ /g, '-')}&${this.season}&${this.sourceteam.replace(/ /g, '-')}`]);
	}
	getplayerreport(index){
		Object.entries(this.players.data[index]).map(data=>{
			data.map(nd=>{
				if(nd ==='Competition Name'){
					this.competitionName = data[1]
				}
				if(nd ==='Player'){
					this.playerName = data[1]
				}
				if(nd ==='Season'){
					this.season = data[1]
				}
				if(nd ==='Position'){
					this.position = data[1]
				}
				if(nd ==='Source Team'){
					this.sourceteam = data[1]
				}
				
			})
		})

		this.router.navigate([`player/reports/${this.competitionName.replace(/ /g, '-')}&${this.season}&${this.playerName.replace(/ /g, '-')}&${this.position.replace(/ /g, '-')}&${this.sourceteam.replace(/ /g, '-')}`]);

	}
	
	sort(sortingname){
		this.sortingname = sortingname 
		this.p=1;
		this.search(1 , sortingname, this.compName)
	}
	searchPlayer(){
		if (!this.searchText.replace(/\s/g, '').length) {
		}
		else{
			this.search(1 , this.searchText, this.compName)
		}
	}
	resetsearch()	{
		this.sortorder = false;
		this.searchText= ''
		if(this.searchText === ''){
			this.search(1 , this.searchText, this.compName);
		}
	}

	search(page, sortingname, name) {
		name=  this.compName
		if(this.sortorder == false){
			this.ASC= 1;
			this.sortorder = true;
			this.sorting = 'asc';
		}else{
			this.ASC= -1;
			this.sortorder = false
			this.sorting = 'dsc';
		}

		this.players =  null;
		var newFilter="";
		if(this.FindPlayer === null){
			this.FindPlayer = undefined
		}
		this.groupName=	this.groupBy(this.selection , 'filter_type')
		if(Object.keys(this.groupName).length > 0){
			for (let keys of Object.keys(this.groupName)) {
				let group_by_list = this.groupName[keys];
				newFilter+="&"+group_by_list[0].filter_type+"=";
				group_by_list.forEach(function (value) {
					newFilter+=value.value+",";
				}); 
			}
		}

		this.http.get(`${environment.apiUrl}/player?page=${page}&limit=${this.itemsPerPage}
		${newFilter}&player=${this.FindPlayer}&competition_name="${this.compName}&sortingname=${this.sortingname}&order=${this.ASC}&search=${this.searchText}`,).pipe(first())
		.subscribe((data: any)  => {
			this.players =  data;
			console.log(this.players)
			this.totalItems = data.total;
			var sorting = this.sorting
			$(document).ready(function(){
				$('th').removeClass('asc dsc')
				$('th[data-name="'+sortingname+'"] , td[data-name="'+sortingname+'"]').addClass(sorting)
				JSON.parse(localStorage.getItem('class')).map(ndata=>{
					$('th[data-name="'+ndata+'"] , td[data-name="'+ndata+'"]').addClass('tdinactive')
					$('button[data-name="'+ndata+'"]').addClass('active')
				})
			})
		})
		
	}

	groupBy(arr, property) {
		return arr.reduce(function(memo, x) {
		  	if (!memo[x[property]]) { memo[x[property]] = []; }
			memo[x[property]].push(x);
		  	return memo;
		}, {});
	}
	  
	// show items on page
	pageitem(itemsPerPage) {
		this.search(1, this.sortingname, this.compName);
	}

	changeclass(){
		if(this.filterClass === 'show'){
			this.filterClass = 'hide'
			this.btntext = 'Show Filter'
		}else{
			this.filterClass = 'show'
			this.btntext = 'Hide Filter'
		}
	}
	returnZero() {return 0}
	getSelection(flname) {
		return this.selection.findIndex(s => s.value === flname.value ) !== -1;
	}
	
	changeHandler(flname, event: KeyboardEvent) {
		// console.log(flname);
		const value = flname.value;
		const index = this.selection.findIndex(u => u.value === value && u.filter_type===flname.filter_type);
		if (index === -1) {// ADD TO SELECTION
			this.selection = [...this.selection, flname];
		} else {// REMOVE FROM SELECTION
		  this.selection = this.selection.filter(user => user.value !== flname.value)
		}
		this.p=1;
		this.search(1, this.sortingname, this.compName)
	}
	
	changeHandler2(filtername, event: KeyboardEvent) {
		// console.log('dd' ,filtername.value);
		const value = filtername;
		const index = this.selection.findIndex(u => u.value === value && u.value.filter_type===filtername.value.filter_type);
		if (index === -1) {// ADD TO SELECTION
			for(let allfilter of [...this.selection, filtername.value]){
				this.selection = allfilter
			}
		} else {// REMOVE FROM SELECTION
		  this.selection = this.selection.filter(user => user.value !== filtername.value)
		}
		this.p=1;
		this.search(1,this.sortingname, this.compName)
	}

	deleteData(): void {
        if (confirm("Are you sure you want to delete ?")){
           this.accountService.deleteData().subscribe(
            data => {
                this.alertService.success('Deleted successfully', { keepAfterRouteChange: true });
				this.search(1,this.sortingname, this.compName);
				this.accountService.getPlayersFilter('').pipe(first())
				.subscribe(filters =>{this.filters = filters})
			},
            error => {
                this.alertService.error(error);
            },
            );
            
        }
       
	}	

	csv(){
		this.CSV.downloadFile(this.players.data,'rowdata', Object.keys(this.players.data[0]))
	}



	
	deleteRound(compname , round): void {
        if (confirm("Are you sure you want to delete "+ compname +" round "+ round+" ?")){
           this.accountService.deleteRound(compname , round).subscribe(
            data => {
                this.alertService.success('Deleted successfully', { keepAfterRouteChange: true });
				this.search(1,this.sortingname, this.compName);
				this.accountService.getPlayersFilter('').pipe(first())
				.subscribe(filters =>{this.filters = filters})
				this.modalService.dismissAll();
			},
            error => {
                this.alertService.error(error);
            },
            );
            
        }
       
	}
	
	deleteCompetation(compname): void {
        if (confirm("Are you sure you want to delete Competation "+ compname+"?")){
           this.accountService.deleteCompitation(compname).subscribe(
            data => {
                this.alertService.success('Deleted successfully', { keepAfterRouteChange: true });
				this.search(1,this.sortingname, this.compName);
				this.accountService.getPlayersFilter('').pipe(first())
				.subscribe(filters =>{this.filters = filters})
				this.modalService.dismissAll();
			},
            error => {
                this.alertService.error(error);
            },
            );
            
        }
       
	}


	open(modal) {
        this.modalService.open(modal, { size: 'sm' });
    }
	open2(modal2) {
        this.modalService.open(modal2, { size: 'sm' });
    }
	
	
}



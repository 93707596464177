<h5 class="mt-2 pt-2 pl-3 pr-3 mb-4 row mr-2 ml-2 justify-content-between align-items-center">Assign Competitions</h5>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-4" *ngIf="filters">
            <ng-select class="mr-2" style="width: 100%;" [items]="newfilters" [multiple]="true" bindLabel="value" placeholder="Competition" [hideSelected]="false" [(ngModel)]="competitionName" (change)="getallcompetition()">
            </ng-select>
        </div>
        <div class="col-sm-3" *ngIf="allusers && users">
            <ng-select class="mr-2" style="width: 100%;" [items]="users" [multiple]="true" bindLabel="name" placeholder="Users" [hideSelected]="false" [(ngModel)]="username" (change)="getalluser()">
                {{username}}
            </ng-select>
        </div>
        <div class="col-sm-3">
            <button class="btn-theme" (click)="assignCompition()">Assign </button>
        </div>
    </div>
</div>

<div class="container-fluid mt-3">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>username</th>
                <th>Email</th>
                <th>Competition</th>
            </tr>
        </thead>
        <tbody *ngIf="restrictions">
            <tr *ngFor="let data of restrictions.data; let i = index">
                <ng-container *ngIf="data.user">
                    <td>{{i + 1}}</td>
                    <td>{{data.user.firstname}} {{data.user.lastname}}</td>
                    <td>{{data.user.username}}</td>
                    <td>{{data.user.email}}</td>
                    <td style="width: 600px;white-space: break-spaces;">
                        <span *ngFor="let compdata of data.competitions">
                            {{compdata.competition_name}}
                            <i class="fas fa-times-circle ml-2" (click)="deleteUser(compdata._id);$event.stopPropagation()"></i>
                        </span>

                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="!restrictions.data">
                <td colspan="4" class="text-center">
                    <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
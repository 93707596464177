import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { Roles } from './_models';

import { HomeComponent } from './home';
import { AuthGuard } from './_helpers';
import { UserComponent } from './user/user.component';
import { AddEditComponent } from './user/add-edit.component';
// import { RoleComponent } from './role/role.component';
// import { RoleAddEditComponent } from './role/add-edit.component';
import { RowDataComponent } from './rowdata/rowdata.component';
import { AddDataFileComponent } from './rowdata/add-file.component';
import { CompetitionsComponent } from './competitions/competitions.component';
import { PlayerComponent } from './player/player.component';
import { PlayerReportsComponent } from './player/reports/report.component';
import { GetReportsComponent } from './reports/report.component';
import { TeamReportsComponent } from './team/report.component';
import { AssignCompetitionsComponent } from './assign-competitions/assign-competitions.component';
import { LogsComponent } from './user/logs.component';
import { DownloadComponent } from './download/download.component';

// const accountModule = () => import('./account/account.module').then(x => x.AccountModule);

const routes: Routes = [
	{ path: 'login', component: LoginComponent },
	// { path: '', component: HomeComponent, canActivate: [AuthGuard] },
	{ path: '', component: CompetitionsComponent, canActivate: [AuthGuard] },
	{
		path: 'user',
		component: UserComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},
	{
		path: 'user/add',
		component: AddEditComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},
	{
		path: 'user/logs',
		component: LogsComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},
	{
		path: 'user/edit/:id',
		component: AddEditComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},

	// { path: 'role', component: RoleComponent, canActivate: [AuthGuard] , data: { roles: [Roles.admin] }},
	// { path: 'role/add', component: RoleAddEditComponent, canActivate: [AuthGuard] , data: { roles: [Roles.admin] }},
	// { path: 'role/edit/:id', component: RoleAddEditComponent, canActivate: [AuthGuard] , data: { roles: [Roles.admin] }},

	{
		path: 'rowdata',
		component: RowDataComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},
	{
		path: 'rowdata/add',
		component: AddDataFileComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},
	{
		path: 'assign-competitions',
		component: AssignCompetitionsComponent,
		canActivate: [AuthGuard],
		data: { roles: [Roles.admin] },
	},
	{
		path: 'competitions',
		component: CompetitionsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'player/:name',
		component: PlayerComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'player/reports/:name',
		component: PlayerReportsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'team/:name',
		component: TeamReportsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'reports',
		component: GetReportsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'download',
		component: DownloadComponent,
		canActivate: [AuthGuard],
	},
	// otherwise redirect to home
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

<h5
	class="mt-2 pt-2 pl-1 pr-3 mb-4 row mr-2 ml-2 justify-content-between align-items-center"
>
	<span>
		<a routerLink="/home" class="back-btn ml-2">
			Back
			<svg viewBox="0 0 512 425.49" fill="#fff">
				<g transform="translate(0 -43.255)">
					<path
						d="M142.716,293.147l-94-107.6,94-107.6a20.926,20.926,0,1,0-31.522-27.532L5.169,171.782a20.915,20.915,0,0,0,0,27.532L111.195,320.686a20.928,20.928,0,0,0,31.521-27.539Z"
					/>
					<path
						d="M359.93,164.619h-339a20.926,20.926,0,1,0,0,41.852h339a110.211,110.211,0,1,1,0,220.422H48.828a20.926,20.926,0,1,0,0,41.852h311.1c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619Z"
					/>
				</g>
			</svg>
		</a>
		<!-- {{ filternewname }} | -->
		<button
			(click)="deletecompitation(filternewname)"
			class="add-btn ml-2"
			*ngIf="user.roles.name === 'admin'"
		>
			<svg
				width="15.632"
				height="17.569"
				viewBox="0 0 15.632 17.569"
				style="margin-right: 10px"
			>
				<g transform="translate(0.2 0.2)">
					<g transform="translate(0 0)">
						<path
							d="M72.6,114.908H61.461a.44.44,0,0,0-.33.11.374.374,0,0,0-.11.33l1.145,9.971a2.179,2.179,0,0,0,2.179,1.915h5.547a2.179,2.179,0,0,0,2.2-1.981l.946-9.949a.374.374,0,0,0-.11-.286A.44.44,0,0,0,72.6,114.908Zm-1.387,10.3a1.277,1.277,0,0,1-1.321,1.145H64.344a1.255,1.255,0,0,1-1.3-1.123l-1.1-9.443H72.114Z"
							transform="translate(-59.414 -110.065)"
							fill="#fc1919"
							stroke="red"
							stroke-width="0.4"
						/>
						<path
							d="M37.784,1.981H33.25v-.7A1.233,1.233,0,0,0,32.063,0h-2.84a1.233,1.233,0,0,0-1.255,1.21q0,.033,0,.067v.7H23.433a.44.44,0,1,0,0,.88H37.784a.44.44,0,1,0,0-.88Zm-5.415-.7v.7H28.848v-.7a.352.352,0,0,1,.374-.4H32a.352.352,0,0,1,.376.326A.348.348,0,0,1,32.37,1.277Z"
							transform="translate(-22.993 0)"
							fill="#fc1919"
							stroke="red"
							stroke-width="0.4"
						/>
					</g>
				</g>
			</svg>
			Delete Records
		</button>
	</span>
	<div class="btn-wrap">
		<div class="searchform">
			<!-- <button (click)="savepdf()" >Download</button> -->
			<form class="form" (ngSubmit)="searchPlayer()">
				<div class="searchwrap mr-2">
					<input
						type="text"
						name="search"
						[(ngModel)]="searchText"
						class="form-control"
						placeholder="Search By Player"
					/>
					<button (click)="searchPlayer()" class="btn-search">
						<i class="fas fa-search"></i>
					</button>
				</div>
				<button
					*ngIf="clearform"
					(click)="resetsearch()"
					class="btn-theme mr-2"
				>
					Clear
				</button>
			</form>
		</div>
		<select
			style="max-width: 170px"
			class="form-control"
			[(ngModel)]="itemsPerPage"
			(ngModelChange)="pageitem((itemsPerPage = $event))"
		>
			<option value="">Show All Records</option>
			<option *ngFor="let level of rowdata" [ngValue]="level.name">
				Top {{ level.name }} Records
			</option>
		</select>

		<button class="btn-theme" (click)="changeclass()">{{ btntext }}</button>
		
		<span *ngIf="players && user.roles.name === 'admin'" style="display: flex">
			<div class="btn-theme">
				Column Visibility
				<div
					class="column-option"
					*ngFor="let ttt of players.data | slice : 0 : 1"
				>
					<button
						(click)="ColumnVisiblity(data.key)"
						[attr.data-name]="data.key"
						class="CL{{ data.key }}"
						*ngFor="let data of ttt | keyvalue : returnZero"
					>
						{{ data.key }}
					</button>
				</div>
			</div>
			<div class="btn-theme">
				Fix Columns
				<div
					class="fix-option"
					*ngFor="let ttt of players.data | slice : 0 : 1"
				>
					<button
						(click)="StickyColumn(data.key)"
						[attr.data-name]="data.key"
						*ngFor="let data of ttt | keyvalue : returnZero"
					>
						{{ data.key }}
					</button>
				</div>
			</div>

			<button class="btn-theme" (click)="csv()">Download CSV</button>
			<!-- <button class="btn-theme " (click)="csv('.buttons-copy')">Copy</button> -->
		</span>
	</div>
</h5>

<hr />
<div class="container-fluid table-wrapper">
	<div class="wrapper">
		<div class="col filtercol {{ filterClass }}">
			<h6 class="btn-wrap">
				Filters
				<svg height="18" class="ml-2" viewBox="0 0 512 512">
					<path
						d="m366.996094 512c-11.046875 0-20-8.953125-20-20v-316.007812h-85.992188c-15.777344 0-29.894531-9.433594-35.96875-24.035157-6.097656-14.660156-2.8125-31.398437 8.367188-42.648437l91.09375-91.632813c11.332031-11.398437 26.425781-17.675781 42.5-17.675781 16.074218 0 31.167968 6.277344 42.5 17.675781l91.09375 91.632813c11.183594 11.246094 14.46875 27.988281 8.371094 42.648437-6.074219 14.601563-20.195313 24.035157-35.972657 24.035157h-21.988281c-11.046875 0-20-8.953126-20-20 0-11.046876 8.953125-20 20-20h19.710938l-89.582032-90.113282c-3.769531-3.789062-8.785156-5.878906-14.132812-5.878906-5.34375 0-10.363282 2.089844-14.132813 5.878906l-89.582031 90.113282h83.714844c22.058594 0 40 17.945312 40 40v316.007812c0 11.042969-8.953125 20-20 20zm0 0"
					/>
					<path
						d="m144.988281 512c-16.074219 0-31.167969-6.277344-42.5-17.675781l-91.09375-91.632813c-11.183593-11.25-14.46875-27.988281-8.367187-42.648437 6.070312-14.601563 20.191406-24.039063 35.96875-24.039063h85.992187v-316.003906c0-11.046875 8.953125-20 20-20s20 8.953125 20 20v316.007812c0 22.054688-17.945312 40-40 40h-83.714843l89.582031 90.113282c3.769531 3.789062 8.789062 5.878906 14.132812 5.878906s10.363281-2.089844 14.132813-5.878906l89.582031-90.113282h-19.710937c-11.046876 0-20-8.953124-20-20 0-11.046874 8.953124-20 20-20h21.988281c15.777343 0 29.894531 9.433594 35.96875 24.035157 6.097656 14.660156 2.816406 31.402343-8.367188 42.648437l-91.09375 91.632813c-11.332031 11.398437-26.425781 17.675781-42.5 17.675781zm0 0"
					/>
				</svg>
			</h6>

			<div *ngIf="filters" class="plfilter">
				<div *ngFor="let filtername of filters | keyvalue : returnZero">
					<div 
						class="filters {{ filtername.key }}" 
						*ngIf="filtername.key != 'Game Position' && filtername.key != 'Source Team'">
						<h6>
							<b>
								<i class="far fa-calendar-check" *ngIf="filtername?.key === 'Season'"></i>
								<i class="far fa-flag" *ngIf="filtername?.key === 'Round'"></i>
								<i class="fas fa-gamepad" *ngIf="filtername?.key === 'Game Position'"></i>
								<i class="fas fa-crosshairs" *ngIf="filtername?.key === 'Position'"></i>
								<i class="fas fa-clock" *ngIf="filtername?.key === 'Minutes Played'"></i>
								{{ filtername.key }}
							</b>
							<label [for]="filtername.key" style="padding: 0; width: auto">
								<input
									[id]="filtername.key"
									type="checkbox"
									class="chkall"
									(change)="changeHandler2(filtername, $event)"
								/>
								check All
							</label>
						</h6>
						<hr />
						<div [ngClass]="{'filter-columns': filtername.key === 'Competition'}">
							<ng-container *ngFor="let flname of filtername.value; let i = index">
								<label *ngIf="flname.value" class="filtervalue">
									<input
										type="checkbox"
										[checked]="flname.isSelected"
										(change)="changeHandler(flname, $event)"
									/>
									<div>{{ flname.value }}</div>
								</label>
							</ng-container>
						</div>
					</div>
				</div>
				
				<div class="filters-container">
					<div class="filters">
						<h6 class="minMatHeading">
							<b> Min Matches Played </b>
						</h6>
						<hr />
						<label class="custom-field one">
							<input
								type="text"
								(keyup)="getMinMatches($event.target.value)"
								[value]="minMatches"
								placeholder=" "
								name="minMatches"
							/>
							<span class="placeholder">Enter Minimum Matches</span>
						</label>
					</div>
					<div class="filters">
						<h6 class="minMatHeading">
							<b>Avg Minutes Range </b>
						</h6>
						<hr />
						<div class="minutesDiv">
							<label class="filtervalue">
								<input
									type="checkbox"
									[checked]="minCheckbox[0].isSelected"
									(change)="changeHandler3($event, 0)"
								/>
								<div>0-29</div>
							</label>
							<label class="filtervalue">
								<input
									type="checkbox"
									[checked]="minCheckbox[1].isSelected"
									(change)="changeHandler3($event, 1)"
								/>
								<div>30-49</div>
							</label>
							<label class="filtervalue">
								<input
									type="checkbox"
									[checked]="minCheckbox[2].isSelected"
									(change)="changeHandler3($event, 2)"
								/>
								<div>51+</div>
							</label>
						</div>
					</div>
				</div>
				
				<!-- Add the Team filters at the end -->
				<div *ngIf="filters['Source Team']" class="filters SourceTeam">
					<h6>
						<b>
							<i class="fas fa-users"></i>
							Team
						</b>
						<label [for]="'SourceTeam'" style="padding: 0; width: auto">
							<input
								[id]="'SourceTeam'"
								type="checkbox"
								class="chkall"
								(change)="changeHandler2({key: 'Source Team', value: filters['Source Team']}, $event)"
							/>
							check All
						</label>
					</h6>
					<hr />
					<div class="source-team-columns">
						<ng-container *ngFor="let flname of filters['Source Team']; let i = index">
							<label *ngIf="flname.value" class="filtervalue">
								<input
									type="checkbox"
									[checked]="flname.isSelected"
									(change)="changeHandler(flname, $event)"
								/>
								<div>{{ flname.value }}</div>
							</label>
						</ng-container>
					</div>
				</div>
												
				
			</div>
			
			
		</div>

		<div class="col col-table" style="overflow: hidden">
			<ag-grid-angular
				class="ag-theme-alpine"
				[columnDefs]="columnDefs"
				[rowData]="rowData"
				[defaultColDef]="defaultColDef"
				rowSelection="single"
				(cellClicked)="onRowClicked($event)"
			></ag-grid-angular>
		</div>
		<span *ngIf="players">
			<ng-container
				*ngFor="
					let player of players.data
						| paginate
							: {
									itemsPerPage: itemsPerPage,
									currentPage: p,
									totalItems: totalItems
							  }
				"
			>
			</ng-container>
		</span>
		<span *ngIf="players" class="pageinationwrap">
			<pagination-controls
				*ngIf="players.pages != 1"
				(pageChange)="search((p = $event), sortingname)"
			></pagination-controls>
		</span>
	</div>
</div>

<div class="text-center mt-4" *ngIf="players && totalItems === 0">
	<svg width="100" viewBox="0 0 496 416">
		<g transform="translate(-8 -48)">
			<path
				d="M476,48H36A28.032,28.032,0,0,0,8,76V436a28.032,28.032,0,0,0,28,28H476a28.032,28.032,0,0,0,28-28V76A28.032,28.032,0,0,0,476,48ZM36,64H476a12.013,12.013,0,0,1,12,12V96H24V76A12.013,12.013,0,0,1,36,64ZM476,448H36a12.013,12.013,0,0,1-12-12V112H488V436a12.013,12.013,0,0,1-12,12Z"
				fill="#9eca01"
			/>
			<path
				d="M40,88h8a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z"
				fill="#9eca01"
			/>
			<path
				d="M72,88h8a8,8,0,0,0,0-16H72a8,8,0,0,0,0,16Z"
				fill="#9eca01"
			/>
			<path
				d="M104,88h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z"
				fill="#9eca01"
			/>
			<path
				d="M136,200a80,80,0,1,0,80,80A80,80,0,0,0,136,200Zm0,144a64,64,0,1,1,64-64,64,64,0,0,1-64,64Z"
				fill="#ff7201"
			/>
			<path
				d="M165.657,250.343a8,8,0,0,0-11.314,0L136,268.686l-18.343-18.343a8,8,0,1,0-11.314,11.314L124.686,280l-18.343,18.343a8,8,0,0,0,11.314,11.314L136,291.314l18.343,18.343a8,8,0,1,0,11.314-11.314L147.314,280l18.343-18.343a8,8,0,0,0,0-11.314Z"
				fill="#ff7201"
			/>
			<path
				d="M448,232H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z"
				fill="#ffab07"
			/>
			<path
				d="M256,272h72a8,8,0,0,0,0-16H256a8,8,0,0,0,0,16Z"
				fill="#9eca01"
			/>
			<path
				d="M448,280H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z"
				fill="#9eca01"
			/>
			<path
				d="M352,304H256a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z"
				fill="#9eca01"
			/>
			<path
				d="M384,304h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"
				fill="#ffab07"
			/>
			<path
				d="M448,256H352a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z"
				fill="#ffab07"
			/>
		</g>
	</svg>
	<h1 class="display-4 mt-4">No Data Found</h1>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar-renderer',
  templateUrl: './progress-bar-renderer.component.html',
  styleUrls: ['./progress-bar-renderer.component.scss']
})
export class ProgressBarRendererComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
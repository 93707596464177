import { Component, OnInit } from '@angular/core';
import { AccountService, AlertService, CSVService } from '@app/_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  users = null;

  constructor(
      private accountService: AccountService, 
      private alertService: AlertService,
      private CSV: CSVService,
      
      ) {}
  

  ngOnInit() {
      this.accountService.getAll()
          .pipe(first())
          .subscribe(users => {this.users = users});
  }
  
    csv(){
        this.CSV.downloadUserFile(this.users.data,'rowdata', Object.keys(this.users.data[0]))
    }
    deleteUser(id): void {
        if (confirm("Are you sure you want to delete ?")){
            this.accountService.delete(id).subscribe(
            data => {
                this.alertService.success('User Deleted successfully', { keepAfterRouteChange: true });
                this.accountService.getAll()
                .pipe(first())
                .subscribe(users => this.users = users);
            },
            error => {
                this.alertService.error(error);
            },
        );
          
      }
     
    }


}
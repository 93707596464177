import { Injectable } from '@angular/core';  
import jsPDF from 'jspdf'
import 'jspdf-autotable'

@Injectable({  
    providedIn: 'root'  
})  
export class PDFervice {  
	
	convert(name, data , option) {
		
		var header = [];
		var newdata =[];
		var index=1;
		
		data.map(x=>{
			var temp=[];
			for(let p in x){
				if(option.includes(p)){
					if(index==1){
						header.push(p)
					}
					temp.push(x[p])
				}else{
				}
			}
			index++;
			newdata.push(temp);
			
		})
		
		var doc = new jsPDF('landscape');
		doc.setFontSize(8);
		doc.text(`${name}`, 8, 8);
        
		doc.setFontSize(11);
		doc.setTextColor(100);
        
          (doc as any).autoTable({
            didParseCell({doc, cell, column}) {
              if (cell === undefined) {
                return;
              }
          
              const hasCustomWidth = (typeof cell.styles.cellWidth === 'number');
          
              if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
                return
              }
          
              let text;
          
              if (cell.raw instanceof Node) {
                text = cell.raw.innerText;
              } else {
                if (typeof cell.raw == 'object') {
                  // not implemented yet
                  // when a cell contains other cells (colSpan)
                  return;
                } else {
                  text = '' + cell.raw;
                }
              }
          
              // split cell string by spaces
              const words = text.split(/\s+/);
          
              // calculate longest word width
              const maxWordUnitWidth = words.map(s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100).reduce((a, b) => Math.max(a, b), 0);
              const maxWordWidth = maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor)
          
              const minWidth = cell.padding('horizontal') + maxWordWidth;
          
              // update minWidth for cell & column
          
              if (minWidth > cell.minWidth) {
                cell.minWidth = minWidth;
              }
          
              if (cell.minWidth > cell.wrappedWidth) {
                cell.wrappedWidth = cell.minWidth;
              }
          
              if (cell.minWidth > column.minWidth) {
                column.minWidth = cell.minWidth;
              }
          
              if (column.minWidth > column.wrappedWidth) {
                column.wrappedWidth = column.minWidth;
              }
            },
			head: [header],
			body: newdata,
			startX: 0,
			
			headStyles: {
				fillColor: "#000"
			}, 	
			columnWidth: 'wrap',
            theme: 'grid',
            didDrawCell:(cell, data)=> {
              // console.log(cell)
            },
          })

		// Open PDF document in new tab
		// doc.output('dataurlnewwindow')

		// Download PDF document  
		doc.save(`${name}.pdf`);
	}
}  
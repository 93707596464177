import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';

import { AccountService } from './_services';
import { User } from './_models';
import { LoaderService } from './_services/loader.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app',
	templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
	user: User;
	userdata: any;
	version: any;
	constructor(
		private accountService: AccountService,
		private loaderService: LoaderService,
		private router: Router,
		private http: HttpClient,
		private renderer: Renderer2
	) {
		this.accountService.user.subscribe((x) => (this.user = x));
	}

	ngOnInit() {
		this.http
			.get(`${environment.verUrl}`)
			.pipe(first())
			.subscribe((version) => {
				this.version = version;
			});
	}
	logout() {
		this.accountService.logout();
	}

	ngAfterViewInit() {
		this.loaderService.httpProgress().subscribe((status: boolean) => {
			if (status) {
				this.renderer.addClass(document.body, 'cursor-loader');
			} else {
				this.renderer.removeClass(document.body, 'cursor-loader');
			}
		});
	}
}


<div class="container-fluid mt-2">
    
    <div class="row align-items-start">
        <div class="col-sm-12 text-right">
            <!-- <select style="max-width: 170px;display: inline-block;" class="form-control" [(ngModel)]="itemsPerPage" (ngModelChange)="pageitem(itemsPerPage = $event)">
                <option  value=''>Show All Records</option>
                <option *ngFor="let level of rowdata" [ngValue]="level.name">Top {{level.name}} Records</option>
            </select> -->


            <span *ngIf="reports">
                <div class="btn-theme">
                    Column Visibility
                    <div class="column-option" *ngFor="let report of reports.data|slice:0:1">
                        <button [attr.data-name]="data.key" *ngFor="let data of report | keyvalue : returnZero" 
                        (click)="ColumnVisiblity(data.key)" [id]="data.key | removespace">{{data.key}}</button>
                    </div>
                </div>
            </span>


            <button class="btn-theme " (click)="csv()">Download CSV</button>
        </div>
        <div class="col-sm-4 player-wrapper">
            <img src="assets/logo.jpg" style="max-width: 60px;">
            <div>
                <b>{{sourceteam}}</b> <br>
                <span>{{competitionName}} | {{season}}</span>
            </div>
        </div>
        <div class="col-sm-8">
            <div id="chart" style="position:relative;z-index:2;width: 100%; height: 60vh;"></div>
        </div>
    </div>
</div>



<hr>
<div class="container-fluid table-wrapper report-component" *ngIf="reports && reports.data.length !=0">
    <ag-grid-angular [style]="'height:'+(reports.data.length * 35+80)  +'px'"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    rowSelection="single"
  ></ag-grid-angular>
    <!-- <table class="table">
        <thead>
            <tr *ngFor="let res of reports.data|slice:0:1">
                <th>Sr. no.</th>
                <ng-container *ngFor="let data of res | keyvalue : returnZero">
                    <th  [class]="data.key"  [attr.data-name]="data.key">{{data.key}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let player of reports.data ;let i =index">
                <td>{{i + 1 }}</td>
                <ng-container  *ngFor="let data of player  | keyvalue : returnZero">
                    <td [class]="data.key" [attr.data-name]="data.key">
                        {{data.value}}
                    </td>
                </ng-container>
            </tr>
        </tbody>
        
        <tfoot>
            <tr>
                <td colspan="7" class="text-right"></td>
                <td>{{totalavgplus}}</td>
                <td>{{totalavg}}</td>
                <td>{{totalCVG}}</td>
                <td>{{totalCVM}}</td>
                <td>{{totalfor}}</td>
                <td>{{totalagain}}</td>
                <td>{{totalmargin}}</td>

            </tr>
        </tfoot> 
    </table>-->

</div>
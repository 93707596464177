<h5 class="mt-2 pt-2 pl-3 pr-3 mb-4 row mr-2 ml-2 justify-content-between align-items-center">Logs
        <a routerLink="user" class="btn-theme">
            Back
        </a>
</h5>
<div class="container-fluid">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>ID</th>
                <th>IP Address</th>
                <th>username</th>
                <th>Message</th>
                <th>Status</th>
                <th>Last Login</th>
            </tr>
        </thead>
        <tbody *ngIf="logs">
            <tr *ngFor="let user of logs.data; let i = index">
                <td>{{user.id}}</td>
                <td>{{user.ip}}</td>
                <td>{{user.username}}</td>
                <td>
                    <div class="alert alert-success mb-0 p-1 pl-2 pr-2" *ngIf="user.status === 'Success'">
                        {{user.message}}
                    </div>
                    <div class="alert alert-danger mb-0 p-1 pl-2 pr-2" *ngIf="user.status === 'Failed'">
                        {{user.message}}
                    </div>
                </td>
                <td>
                    <label class="badge badge-success" *ngIf="user.status === 'Success'">{{user.status}}</label>
                    <label class="badge badge-danger" *ngIf="user.status === 'Failed'">{{user.status}}</label>
                    
                </td>
                <td>{{user.last_login_date | date: 'dd MMM yyyy'}} | {{user.last_login_date | date: 'h:mm a '}}</td>
             
            </tr>
        </tbody>
    </table>

</div>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import {
	AccountService,
	AlertService,
	CSVService,
	FileService,
	PDFervice,
} from '@app/_services';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Options } from '@angular-slider/ngx-slider';
import { CapitalizeWordsPipe } from '@app/_helpers/capitalize-words.pipe';
import { ProgressBarRendererComponent } from './progress-bar-renderer/progress-bar-renderer.component';

@Component({
	templateUrl: 'competitions.component.html',
	styleUrls: ['competitions.component.scss'],
})
export class CompetitionsComponent implements OnInit {
	user = JSON.parse(localStorage.getItem('sportsuser'));
	form: FormGroup;
	playersData = null;
	searchText: any;
	p: number;
	itemsPerPage: any;
	totalItems: any;
	items: any;
	players: any;
	columnDefs: any;
	rowData: [];
	defaultColDef: any;
	FindPlayer: any;
	filternewname: any;

	rowdata = [
		{ id: 1, name: '10' },
		{ id: 2, name: '20' },
		{ id: 3, name: '50' },
		{ id: 4, name: '100' },
		{ id: 5, name: '200' },
		{ id: 5, name: '500' },
		{ id: 5, name: '1000' },
	];

	filters: any;
	groupName: any;
	myCheckBox: any;
	selection = [];
	filterClass = 'show';
	btntext = 'Hide Filter';
	findCVG: any;
	sortorder: boolean;
	ASC: number;
	asc: boolean = false;
	dsc: boolean = false;
	sorting: any;
	sortingname = 'CVG';
	tablehead: any;
	clearform: boolean = false;
	colmvis = [];
	fix = [];
	highCVM: any;
	highCVG: any;
	queryarr: string = '';
	minMatches: string = '';
	minMinutes: string = '0';
	maxMinutes: string = '120';
	minCheckbox: any = [
		{ isselected: false },
		{ isselected: false },
		{ isselected: false },
	];

	options: Options = {
		floor: 0,
		ceil: 120,
		getPointerColor: (value: number): string => {
			return '#9eca01';
		},
		getSelectionBarColor: (value: number): string => {
			return '#9eca01';
		},
	};

	capitalizePipe: CapitalizeWordsPipe = new CapitalizeWordsPipe();

	constructor(
		private formBuilder: FormBuilder,
		private accountService: AccountService,
		private route: ActivatedRoute,
		private router: Router,
		private CSV: CSVService,
		private service: FileService,
		private alertService: AlertService,
		private http: HttpClient,
		private PDF: PDFervice
	) {
		this.route.params.subscribe(
			(params) => (this.playersData = params.Players)
		);
	}

	ngOnInit(): void {
		this.itemsPerPage = '100'; // Set default to top 100 records

		// Fetch the initial table head data
		this.http
			.get(`${environment.apiUrl}/calculate/player?page=1&limit=1`)
			.pipe(first())
			.subscribe((data: any) => {
				this.tablehead = data;
			});

		// Check for existing column visibility settings
		if (
			localStorage.getItem('class') != null &&
			localStorage.getItem('class') != undefined
		) {
			this.colmvis = JSON.parse(localStorage.getItem('class'));
		} else {
			this.colmvis = [];
		}

		// Check for existing fixed column settings
		if (
			localStorage.getItem('fixclass') != null &&
			localStorage.getItem('fixclass') != undefined
		) {
			this.fix = JSON.parse(localStorage.getItem('fixclass'));
		} else {
			this.fix = [];
		}

		// Fetch filter data and handle initial selection
		this.accountService
			.getPlayersFilter('')
			.pipe(first())
			.subscribe((filters) => {
				this.filters = filters;

				// Sort Season filter in descending order
				if (this.filters && this.filters.Season) {
					this.filters.Season.sort((a, b) => b.value - a.value);
					this.selection = [
						{
							name: 'Season',
							filter_type: 'season',
							value: this.filters.Season[0].value,
						},
					];
					this.search(1, this.sortingname); // Trigger search with initial selection
				}
			});

		// Additional event listeners for DOM elements
		$(document).on('change', '.chkall', function () {
			if ($(this).is(':checked')) {
				$(this)
					.parents('h6')
					.nextAll('label')
					.find('input')
					.prop('checked', true);
			} else {
				$(this)
					.parents('h6')
					.nextAll('label')
					.find('input')
					.prop('checked', false);
			}
		});

		$(document).on('change', '.flname', function () {
			$(this)
				.parents('.filters')
				.find('h6')
				.find('input')
				.prop('checked', false);
		});

		$(document).on('click', '[col-id="Player"]', function () {
			var pname = $(this).html();
			var season = $(document)
				.find('.ag-row-selected [col-id="Season"]')
				.html();
		});
	}

	getFilterData(queryArr) {
		this.accountService
			.getPlayersFilter(queryArr)
			.pipe(first())
			.subscribe((filters) => {
				this.filters = filters;
				// Sort Season filter in descending order
				if (this.filters && this.filters.Season) {
					this.filters.Season.sort((a, b) => b.value - a.value);
				}
				this.selection = [
					{
						name: 'Season',
						filter_type: 'season',
						value: this.filters.data.Season.slice(-1)[0].value,
					},
				];
				$(document).ready(function () {
					$('.Season')
						.find('label')
						.last()
						.find('input')
						.attr('checked', 'checked');
				});
				this.search(1, this.sortingname);
			});
	}

	changeclass() {
		if (this.filterClass === 'show') {
			this.filterClass = 'hide';
			this.btntext = 'Show Filter';
		} else {
			this.filterClass = 'show';
			this.btntext = 'Hide Filter';
		}
	}

	returnZero() {
		return 0;
	}

	getSelection(flname) {
		return this.selection.findIndex((s) => s.value === flname) !== -1;
	}

	changeHandler(flname, event: KeyboardEvent) {
		const value = flname.value;
		const index = this.selection.findIndex(
			(u) => u.value === value && u.filter_type === flname.filter_type
		);
		if (index === -1) {
			this.selection = [...this.selection, flname];
		} else {
			this.selection = this.selection.filter(
				(user) => user.value !== flname.value
			);
		}
		this.p = 1;
		this.sortorder = true;
		this.search(1, this.sortingname);
	}

	changeHandler2(filtername, event: KeyboardEvent) {
		const value = filtername;
		const index = this.selection.findIndex(
			(u) =>
				u.value === value &&
				u.value.filter_type === filtername.value.filter_type
		);
		if (index === -1) {
			for (let allfilter of [...this.selection, filtername.value]) {
				this.selection = allfilter;
			}
		} else {
			this.selection = this.selection.filter(
				(user) => user.value !== filtername.value
			);
		}
		this.p = 1;
		this.sortorder = true;
		this.search(1, this.sortingname);
	}

	changeHandler3(event: KeyboardEvent, index) {
		this.minCheckbox[index].isselected =
			!this.minCheckbox[index].isselected;
		this.search(1, this.sortingname);
	}

	resetCalculations() {
		this.FindPlayer = undefined;
	}

	sort(sortingname) {
		this.sortingname = sortingname;
		this.p = 1;
		this.search(1, sortingname);
	}

	StickyColumn(key) {
		if (!this.fix.includes(key)) {
			this.fix.push(key);
		} else {
			this.fix.splice(this.fix.indexOf(key), 1);
		}
		localStorage.setItem('fixclass', JSON.stringify(this.fix));
		this.search(1, this.sortingname);
	}

	ColumnVisiblity(key) {
		if (!this.colmvis.includes(key)) {
			this.colmvis.push(key);
		} else {
			this.colmvis.splice(this.colmvis.indexOf(key), 1);
		}
		localStorage.setItem('class', JSON.stringify(this.colmvis));
		this.search(1, this.sortingname);
	}

	searchPlayer() {
		if (!this.searchText.replace(/\s/g, '').length) {
		} else {
			this.FindPlayer = this.searchText;
			this.search(1, this.searchText);
			this.clearform = true;
		}
	}

	resetsearch() {
		this.clearform = false;
		this.sortorder = true;
		this.searchText = '';
		this.FindPlayer = '';
		this.search(1, this.searchText);
	}

	onGridReady($event) {
		var newdata;
	}

	changeDesc(value, desc) {
		for (var i of JSON.parse(localStorage.getItem('fixclass'))) {
			if (i == value) {
				i = desc;
				break;
			}
		}
	}

	onRowClicked(event) {
		if (event.colDef.field === 'Player') {
			this.router.navigate([
				`player/reports/${event.data['Competition Name'].replace(
					/ /g,
					'-'
				)}&${event.data.Season}&${event.data.Player.replaceAll(
					'-',
					'--'
				).replace(/ /g, '-')}&${event.data.Position.replace(
					/ /g,
					'-'
				)}&${event.data['Source Team'].replace(/ /g, '-')}&${
					event.data.CVG
				}&${this.highCVM}&${this.highCVG}&${event.data.CVM}`,
			]);
		}
		if (event.colDef.field === 'Source Team') {
			this.router.navigate([
				`team/${event.data['Competition Name'].replace(/ /g, '-')}&${
					event.data.Season
				}&${event.data['Source Team'].replace(/ /g, '-')}`,
			]);
		}
	}

	search(page, sortingname) {
		this.ASC = -1;
		this.players = null;
		var newFilter = '';
		if (this.FindPlayer === null) {
			this.FindPlayer = undefined;
		}

		this.groupName = this.groupBy(this.selection, 'filter_type');

		if (Object.keys(this.groupName).length > 0) {
			for (let keys of Object.keys(this.groupName)) {
				let group_by_list = this.groupName[keys];
				newFilter += '&' + group_by_list[0].filter_type + '=';
				group_by_list.forEach(function (value) {
					newFilter += value.value + ',';
				});
			}
		}
		if (this.minMatches.length > 0) {
			newFilter += '&mat=' + this.minMatches;
		}
		if (this.minCheckbox[0].isselected) {
			this.maxMinutes = '29';
		}
		if (this.minCheckbox[1].isselected) {
			this.maxMinutes = '49';
		}
		if (this.minCheckbox[2].isselected) {
			this.maxMinutes = '120';
			this.minMinutes = '51';
		}
		if (this.minCheckbox[1].isselected) {
			this.minMinutes = '30';
		}
		if (this.minCheckbox[0].isselected) {
			this.minMinutes = '0';
		}
		if (this.minCheckbox.some((x) => x.isselected)) {
			newFilter += '&min=' + this.minMinutes + ',' + this.maxMinutes;
		}

		this.queryarr = `${newFilter}&player=${this.FindPlayer}&sortingname=${this.sortingname}&order=${this.ASC}&search=${this.searchText}`;
		this.getFilterData(this.queryarr);

		this.http
			.get(
				`${environment.apiUrl}/calculate/player/?page=${page}&limit=${this.itemsPerPage}${this.queryarr}`
			)
			.pipe(first())
			.subscribe((data: any) => {
				this.players = data;
				console.log('Players:', this.players);
				this.totalItems = data.total;
				var cvgdata = [];
				var maxValues = {};
				for (let x of data.data) {
					cvgdata.push(x['CVG']);
					// Track max values for relevant columns
					for (let field of ['CVG', 'CVM', 'CPC%', 'AVG-']) {
						if (x[field] !== undefined) {
							if (!maxValues[field]) {
								maxValues[field] = 0;
							}
							maxValues[field] = Math.max(
								maxValues[field],
								x[field]
							);
						}
					}
				}

				if (this.searchText == undefined) {
					this.highCVM = this.players.data.reduce(
						(acc, shot) =>
							(acc = acc > shot['CVM'] ? acc : shot['CVM']),
						0
					);
					this.highCVG = this.players.data.reduce(
						(acc, shot) =>
							(acc = acc > shot['CVG'] ? acc : shot['CVG']),
						0
					);
				}
				this.defaultColDef = {
					resizable: true,
					sortable: true,
					minWidth: 100,
				};
				var columnDefss = [];
				columnDefss.push({
					headerName: 'Rank',
					field: 'Rank',
					valueGetter: function (params) {
						return params.node.rowIndex + 1;
					},
					valueFormatter: function (params) {
						return params.value;
					},
					width: 100,
					pinned: 'left',
				});

				for (let x of Object.keys(this.players?.data?.[0] || {})) {
					let left;
					let getWidth = x.length * 10;
					let hidecol;
					let name = x;
					let cellRendererFramework = null;

					if (localStorage.getItem('fixclass')) {
						if (
							JSON.parse(
								localStorage.getItem('fixclass')
							).includes(x)
						) {
							left = 'left';
						} else {
							left = '';
						}
					}

					if (JSON.parse(localStorage.getItem('class'))) {
						if (
							JSON.parse(localStorage.getItem('class')).includes(
								x
							)
						) {
							hidecol = true;
						} else {
							hidecol = false;
						}
					}

					if (x === 'Source Team') {
						name = 'Team';
						getWidth = 200;
					}

					if (x === 'Competition Name') {
						getWidth = 200;
					}

					if (x === 'Position') {
						getWidth = 150;
					}

					if (x === 'Player') {
						getWidth = 220;
					}

					if (
						x === 'CVG' ||
						x === 'CVM' ||
						x === 'CPC%' ||
						x === 'AVG-'
					) {
						cellRendererFramework = ProgressBarRendererComponent;
					}

					if (x === 'AVG-') {
						name = 'NEGS';
					}

					let columnDef = {
						headerName: name,
						field: x,
						width: getWidth,
						hide: hidecol,
						pinned: left,
						sort: x === 'CVG' ? 'desc' : null,
						valueGetter:
							x === 'Player'
								? (params) =>
										this.capitalizePipe.transform(
											params.data.Player
										)
								: null,
						cellRendererFramework: cellRendererFramework,
						cellRendererParams: {
							maxValue: maxValues[x] || 100, // Pass the max value for the column
							fieldName: x, // Pass the field name here
						},
					};

					// Directly push columns into columnDefss
					columnDefss.push(columnDef);
				}
				// Define the desired order
				const order = [
					'Rank',
					'Season',
					'Competition Name',
					'Player',
					'Source Team',
					'Position',
					'MAT',
					'MIN',
					'AVG+',
					'CVG',
					'CVM',
					'CPC%',
					'AVG-',
				];

				// Reorder columns
				columnDefss = columnDefss.sort((a, b) => {
					const indexA = order.indexOf(a.field);
					const indexB = order.indexOf(b.field);
					if (indexA === -1 && indexB === -1) {
						return 0;
					}
					if (indexA === -1) {
						return 1;
					}
					if (indexB === -1) {
						return -1;
					}
					return indexA - indexB;
				});

				this.columnDefs = columnDefss;
				this.rowData = this.players.data;
				$(document).ready(function () {
					if (localStorage.getItem('class') != null) {
						JSON.parse(localStorage.getItem('class')).map(
							(ndata) => {
								$('button[data-name="' + ndata + '"]').addClass(
									'active'
								);
								$(
									'.fix-option button[data-name="' +
										ndata +
										'"]'
								).hide();
							}
						);
					}
				});
			});
	}

	getMinMatches(val: string) {
		console.log('Get Min Matches', val, this.sortingname, this.sortorder);
		this.minMatches = val;
		this.search(1, this.sortingname);
	}

	groupBy(arr, property) {
		return arr.reduce(function (memo, x) {
			if (!memo[x[property]]) {
				memo[x[property]] = [];
			}
			memo[x[property]].push(x);
			return memo;
		}, {});
	}

	pageitem(itemsPerPage) {
		this.sortorder = true;
		this.search(1, this.sortingname);
	}

	csv() {
		this.CSV.downloadFile(
			this.players.data,
			'rowdata',
			Object.keys(this.players.data[0])
		);
	}

	deletecompitation(name): void {
		if (
			this.minMatches.length > 0 ||
			this.minCheckbox.some((x) => x.isselected)
		) {
			this.alertService.success(
				'Minimum Matches, Minutes should be empty'
			);
			return;
		}
		if (confirm('Are you sure you want to delete ?')) {
			this.accountService.deletePlayerFiltered(this.queryarr).subscribe(
				(data) => {
					this.alertService.success('Deleted successfully', {
						keepAfterRouteChange: true,
					});

					this.search(1, this.sortingname);
				},
				(error) => {
					this.alertService.error(error);
				}
			);
		}
	}
}

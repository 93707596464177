<h6 class="mt-2 pt-2 pl-3 pr-3 mb-4 row mr-2 ml-2 justify-content-between align-items-center">
    <span>View Report | <a routerLink="/home" class="back-btn">
        Back
        <svg  viewBox="0 0 512 425.49" fill="#fff"><g transform="translate(0 -43.255)"><path d="M142.716,293.147l-94-107.6,94-107.6a20.926,20.926,0,1,0-31.522-27.532L5.169,171.782a20.915,20.915,0,0,0,0,27.532L111.195,320.686a20.928,20.928,0,0,0,31.521-27.539Z"/><path d="M359.93,164.619h-339a20.926,20.926,0,1,0,0,41.852h339a110.211,110.211,0,1,1,0,220.422H48.828a20.926,20.926,0,1,0,0,41.852h311.1c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619Z"/></g></svg>
    </a></span>
    <div class="btn-wrap">
        <select class="form-control mr-2"  [(ngModel)]="reportfor" (change)="getreportfor()">
            <option value="team">Team</option>
            <option value="player">Player</option>
        </select>
        <span class=" mr-2" *ngIf="teamName && reportfor === 'team'" >
            <ng-select style="width: 220px;" [items]="teamName.data" 
                (change)="getReport(reportfor , name)"
                [multiple]="false"
                bindLabel="name"
                placeholder="Select team"
                [(ngModel)]="name">
            </ng-select>
        </span>
        <span class=" mr-2" *ngIf="playerName && reportfor != 'team'">
            <ng-select style="width: 220px;" [items]="playerName.data" 
                [multiple]="false"
                (change)="getReport(reportfor , name)"
                bindLabel="name"
                placeholder="Select Player"
                [(ngModel)]="name">
            </ng-select>
        </span>

        <span *ngIf="competitions">
            <ng-select class="mr-2" style="width: 300px;" [items]="competitions" 
                [multiple]="false"
                bindLabel="value"
                placeholder="Select competition"
                [(ngModel)]="competitionName">
            </ng-select>
        </span>

        <div *ngIf="season" class="mr-2">
            <ng-select style="width: 220px;" [items]="season" [multiple]="false" bindLabel="value" 
            placeholder="Select Season" [(ngModel)]="seasonName">
            </ng-select>
        </div>


        <button class="btn-theme" style="font-size: 14px;" (click)="getFinalReport(reportfor , name)">View Report</button>
        
    </div>
</h6>
<ng-template #info let-modal>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <img src="assets/close.svg">
      </button>
    <div class="modal-body">

        <div class="t" *ngIf="player">
            <img src="assets/avtar.jpg" *ngIf="!player" height="80px">
            <ng-container *ngIf="player">
                <img src="assets/avtar.jpg" *ngIf="!player.data.image" height="80px">
                <img [src]="filepath+player.data.image" *ngIf="player.data.image" height="80px">
            </ng-container>
            <div class="row mt-3">
                <p class="mt-2 col-sm-6" *ngIf="player.data.nick_name">
                    <small><b>Nick Name</b></small> <br>
                    {{player.data.nick_name}}
                </p>
                <p class="mt-2 col-sm-6" *ngIf="player.data.player_name">
                    <small><b>Name</b></small> <br>
                    {{player.data.player_name}}
                </p>
                <p class="mt-2 col-sm-6" *ngIf="player.data.birth_place">
                    <small><b>Nick Name</b></small> <br>
                    {{player.data.birth_place}}
                </p>
                <p class="mt-2 col-sm-6" *ngIf="player.data.dob">
                    <small><b>DOB</b></small> <br>
                    {{player.data.dob | date: 'dd/MM/yyyy'}}
                </p>
                <p class="mt-2 col-sm-6" *ngIf="player.data.age">
                    <small><b>Age</b></small> <br>
                    {{player.data.age}}
                </p>
                <p class="mt-2 col-sm-6" *ngIf="player.data.height">
                    <small><b>Height</b></small> <br>
                    {{player.data.height}}
                </p>
                <p class="mt-2 col-sm-6" *ngIf="player.data.weight">
                    <small><b>Weight</b></small> <br>
                    {{player.data.weight}}
                </p>
            </div>
        </div>


        
    </div>
</ng-template>

<div class="text-center mt-4" *ngIf="!reports && nodata === false">
    <svg width="100" viewBox="0 0 496 416"><g transform="translate(-8 -48)"><path d="M476,48H36A28.032,28.032,0,0,0,8,76V436a28.032,28.032,0,0,0,28,28H476a28.032,28.032,0,0,0,28-28V76A28.032,28.032,0,0,0,476,48ZM36,64H476a12.013,12.013,0,0,1,12,12V96H24V76A12.013,12.013,0,0,1,36,64ZM476,448H36a12.013,12.013,0,0,1-12-12V112H488V436a12.013,12.013,0,0,1-12,12Z" fill="#9eca01"/><path d="M40,88h8a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M72,88h8a8,8,0,0,0,0-16H72a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M104,88h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M136,200a80,80,0,1,0,80,80A80,80,0,0,0,136,200Zm0,144a64,64,0,1,1,64-64,64,64,0,0,1-64,64Z" fill="#ff7201"/><path d="M165.657,250.343a8,8,0,0,0-11.314,0L136,268.686l-18.343-18.343a8,8,0,1,0-11.314,11.314L124.686,280l-18.343,18.343a8,8,0,0,0,11.314,11.314L136,291.314l18.343,18.343a8,8,0,1,0,11.314-11.314L147.314,280l18.343-18.343a8,8,0,0,0,0-11.314Z" fill="#ff7201"/><path d="M448,232H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z" fill="#ffab07"/><path d="M256,272h72a8,8,0,0,0,0-16H256a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M448,280H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z" fill="#9eca01"/><path d="M352,304H256a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" fill="#9eca01"/><path d="M384,304h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" fill="#ffab07"/><path d="M448,256H352a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" fill="#ffab07"/></g></svg>
    <h1 class="display-4 mt-4">Please Select Option </h1>
</div>
<div class="container-fluid mt-5">
    
    <div class="row align-items-start">
        
         <div class="col-sm-4 dark" *ngIf="reports">
            <div class="form-links text-right" *ngIf="reportfor != 'team'">
                <label  (click)="viewInfo(info)" class="mr-2" >
                    <svg width="21" viewBox="0 0 576 512"><path fill="#9eca01" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>
                     View Info
                </label>
            </div>

            <div class="player-wrapper">
                <!-- <img src="assets/avtar.jpg"> -->
                <ng-container  *ngIf="reportfor != 'team'">
                    <img src="assets/avtar.jpg" *ngIf="!player">
                    <ng-container *ngIf="player">
                        <img src="assets/avtar.jpg" *ngIf="!player.data.image">
                        <img [src]="filepath+player.data.image" *ngIf="player.data.image">
                    </ng-container>
                </ng-container>
                <img src="assets/logo.jpg" style="max-width: 60px;" *ngIf="reportfor != 'player'">
                <div>
                    <b>{{name}}</b> <br>
                    <ng-container *ngIf="reportfor != 'team'">
                        <ng-container *ngIf="sourceTeam">
                            <a class="color" routerLink="/competitions/{{this.competitionName.value.split(' ').join('-')}}" *ngIf="competitionName">{{competitionName.value}}</a>
                            <br>
                            <span *ngFor="let teamname of sourceTeam|slice:0:1">{{teamname.value}}</span> 
                        </ng-container>
                        <br>
                        <ng-container *ngIf="Position">
                            <small *ngFor="let Positionname of Position|slice:0:1">{{Positionname.value}}</small> 
                        </ng-container>
                    </ng-container>
                    
                </div>
            </div>
            <div >
                <table class="table-white text-center" *ngIf="stats">
                    <tr>
                        <td colspan="2"style="border: 0px;"></td>
                        <td style="border: 0px;">Competition Rank</td>
                        <td style="border: 0px;">Position Rank</td>
                    </tr>
                    <tr>
                        <td>Matches</td>
                        <td>{{stats.data.total_matches}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Avg Min</td>
                        <td>{{stats.data.min}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Avg CVG</td>
                        <td><b class="color">{{stats.data.cvg}}</b></td>
                        <td>{{stats.data.cvg_rank}}</td>
                        <td>{{stats.data.position_cvg_rank}}</td>
                    </tr>
                    <tr>
                        <td>Avg CVM</td>
                        <td><b class="color">{{stats.data.cvm}}</b></td>
                        <td>{{stats.data.cvm_rank}}</td>
                        <td>{{stats.data.position_cvm_rank}}</td>
                    </tr>
                    <tr>
                        <td>Avg CPC</td>
                        <td><b class="color">{{stats.data.cpc}}</b></td>
                        <td>{{stats.data.cpc_rank}}</td>
                        <td>{{stats.data.position_cpc_rank}}</td>
                    </tr>
                    <tr>
                        <td>Avg NegPM</td>
                        <td><b class="color">{{stats.data.neg_pm}}</b></td>
                        <td></td>
                        <td>{{stats.data.position_neg_pm}}</td>
                    </tr>
                </table>
                <h6 class="color mt-4" *ngIf="stats">Top {{stats.data.total_matches}}</h6>
            </div> 
            
        </div>
        <div class="col-sm-8">
            <div class="text-center mt-4" *ngIf="nodata">
                <svg width="100" viewBox="0 0 496 416"><g transform="translate(-8 -48)"><path d="M476,48H36A28.032,28.032,0,0,0,8,76V436a28.032,28.032,0,0,0,28,28H476a28.032,28.032,0,0,0,28-28V76A28.032,28.032,0,0,0,476,48ZM36,64H476a12.013,12.013,0,0,1,12,12V96H24V76A12.013,12.013,0,0,1,36,64ZM476,448H36a12.013,12.013,0,0,1-12-12V112H488V436a12.013,12.013,0,0,1-12,12Z" fill="#9eca01"/><path d="M40,88h8a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M72,88h8a8,8,0,0,0,0-16H72a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M104,88h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M136,200a80,80,0,1,0,80,80A80,80,0,0,0,136,200Zm0,144a64,64,0,1,1,64-64,64,64,0,0,1-64,64Z" fill="#ff7201"/><path d="M165.657,250.343a8,8,0,0,0-11.314,0L136,268.686l-18.343-18.343a8,8,0,1,0-11.314,11.314L124.686,280l-18.343,18.343a8,8,0,0,0,11.314,11.314L136,291.314l18.343,18.343a8,8,0,1,0,11.314-11.314L147.314,280l18.343-18.343a8,8,0,0,0,0-11.314Z" fill="#ff7201"/><path d="M448,232H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z" fill="#ffab07"/><path d="M256,272h72a8,8,0,0,0,0-16H256a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M448,280H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z" fill="#9eca01"/><path d="M352,304H256a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" fill="#9eca01"/><path d="M384,304h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" fill="#ffab07"/><path d="M448,256H352a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" fill="#ffab07"/></g></svg>
                <h1 class="display-4 mt-4">No data found</h1>
            </div>
            <div id="chart" style="position:relative;z-index:2;width: 100%;"></div>
        </div>
    </div>
</div>


<hr>
<div class="container-fluid table-wrapper report-component" *ngIf="reports" style="overflow-y: hidden;">
    <div class="text-right">
        <div class="btn-theme" *ngIf="reports">
            Column Visibility
            <div class="column-option" *ngFor="let ttt of reports.data|slice:0:1    ">
                <button [attr.data-name]="data.key" *ngFor="let data of ttt | keyvalue : returnZero" 
                (click)="ColumnVisiblity(data.key)" [id]="data.key | removespace">{{data.key}}</button>
            </div>
        </div>
        <br><br>
    </div>
    <ag-grid-angular [style]="'height:'+(reports.data.length * 35+80)  +'px'"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    rowSelection="single"
  ></ag-grid-angular>
    <!-- <table  class="table">
        <thead>
            <tr *ngFor="let res of reports.data|slice:0:1 ">
                <th>Sr. no.</th>
                <th *ngFor="let data of res | keyvalue : returnZero" class="{{data.key}}" [attr.data-name]="data.key">{{data.key}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let team of reports.data;let i =index">
                <td>{{i + 1 }}</td>
                <td *ngFor="let data of team | keyvalue : returnZero" class="{{data.key}}" [attr.data-name]="data.key">{{data.value}}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td [colSpan]="tdlength" class="text-right" class="colspan"></td>
                <td class="MIN" *ngIf="totalmin && reportfor != 'team'" [attr.data-name]="'MIN'">{{totalmin}}</td>
                <td class="AVG+" *ngIf="totalavgplus"  [attr.data-name]="'AVG+'">{{totalavgplus}}</td>
                <td class="AVG-" *ngIf="totalavg" [attr.data-name]="'AVG-'">{{totalavg}}</td>
                <td class="CVG" *ngIf="totalCVG" [attr.data-name]="'CVG'">{{totalCVG}}</td>
                <td class="CVM" *ngIf="totalCVM" [attr.data-name]="'CVM'">{{totalCVM}}</td>
            </tr>
        </tfoot>
    </table> -->

</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-progress-bar-renderer',
  templateUrl: './progress-bar-renderer.component.html',
  styleUrls: ['./progress-bar-renderer.component.scss']
})
export class ProgressBarRendererComponent {
  private params: any;
  value: number;
  maxValue: number; // Default max value is now dynamic
  fieldName: string;

  agInit(params: any): void {
    this.params = params;
    this.fieldName = params.fieldName; 
    this.value = params.value;
    this.maxValue = params.maxValue;
  }

  processValue(value: any, fieldName: string): number {
    if (fieldName === 'CPC%' && typeof value === 'string' && value.endsWith('%')) {
      return parseFloat(value.slice(0, -1));
    }
    return value;
  }

  getPercentageWidth(value: number, maxValue: number): string {
    const updatedVal = this.processValue(value, this.fieldName);
    const positiveValue = Math.abs(updatedVal);
    return `${(positiveValue / maxValue) * 100}%`;
  }

  getGradientStyle(fieldName: string): string {
    switch (fieldName) {
      case 'CVG':
        return 'linear-gradient(to right, #739ACB, #D1E2F3)';
      case 'CVM':
        return 'linear-gradient(to right, #CA6523, #F9D8C1)';
      case 'AVG-':
        return 'linear-gradient(to right, #FF1616, #FFBFBF)';
      default:
        return 'linear-gradient(to right, #97D25A, #E9F5D5)';
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountService, AlertService, ChartService, FileService } from '@app/_services';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
// import * as Highcharts from 'highcharts';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({ 
    selector: 'team-report',
    templateUrl: 'report.component.html',
	styleUrls: ['report.component.scss'] ,
})
export class GetReportsComponent implements  OnInit {

    pic:any;
    picture:any;
    filepath=`${environment.imgPath}/`;

    form: FormGroup;
    teamsData = null;
	
	itemsPerPage:any;
	totalItems: any;
	items:any;

	filternewname:any;
	viewteam:any;
    reports:any;

    teamName:any;
    competitions:any;
    filters:any;

    // CREATE FILTER 
    competitionName:any;
    seasonName:any;

    NewData:any = []
    NewDataAVG:any = []
    Oponent:any = []
    season:any;
    competition:any = [];
    nodata= false;
    reportfor:any ='team';
    playerName:any;
    player:any;
    name:any;


    stats:any;
    totalmin:any;
    totalavg:any;
    totalavgplus:any;
    totalCVG:any;
    totalCVM:any;
    sourceTeam:any;
    Position:any;
    totalfor:any;
    totalagain:any;
    totalmargin:any;

    columnDefs: any;
	rowData: [];
	defaultColDef:any;
    colmvis=[];
    columnOption=[];
    date=new Date();
	constructor(
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
		private accountService: AccountService, 
        private route: ActivatedRoute,
        private router: Router,
        private service: FileService,
        private alertService: AlertService,
		private http: HttpClient,
        private ChartService:ChartService
    ) { 
        this.route.params.subscribe(params => this.teamsData = params.teams)
    }
	ngOnInit(): void {
		this.itemsPerPage = 100
        this.getTeamName();
        this.getPlayerName();

        if(localStorage.getItem('class') != null && localStorage.getItem('class') != undefined){
			this.colmvis = JSON.parse(localStorage.getItem('class'))
		}else{
			this.colmvis = [];
		}
        
        // $(document).on('click' , '.column-option button' , function(){
		// 	this.thead = $(this).attr('data-name')
		// 	this.cln =  document.getElementsByClassName(this.thead)
		// 	if($('td , th').hasClass(this.thead)){
		// 		$(document).find(this.cln).toggleClass('tdinactive')
		// 		$(this).toggleClass('active')
		// 	}else{
		// 		$(document).find(this.cln).removeClass('tdinactive');
		// 		$(this).removeClass('active')
		// 	}

		// 	if(!colmvis.includes(this.thead)){          //checking weather array contain the id
		// 		colmvis.push(this.thead);               //adding to array because value doesnt exists
		// 	}else{
		// 		colmvis.splice(colmvis.indexOf(this.thead), 1);  //deleting
		// 	}
		// 	localStorage.setItem('class' ,JSON.stringify(colmvis))
			
		// })
    }

	returnZero() {return 0}
    CreateStat(season,compname, plname ,type ){
        this.http.get(`${environment.apiUrl}/report/stats/player?season=${season}&competition_name=${compname}&${type}=${plname}`).pipe(first()).subscribe(
            stats=>{
                this.stats = stats
            }
        )
    }

    getTeamName(){
        this.accountService.getTeamsNames().subscribe(teamName =>{this.teamName = teamName})
    }
    getPlayerName(){
        this.accountService.getPlayersNames().subscribe(playerName =>{this.playerName = playerName})
    }

    
    getreportfor(){
        this.name = null;
        this.competitionName = null;
        this.seasonName= null;
        this.getReport(this.reportfor, this.name);
        document.getElementById('chart').innerHTML = '';
        this.reports = null;
    }
    
	getReport(type , name){
        console.log(name)
        this.http.get(`${environment.apiUrl}/filter/type?type=${type}&name=${name}`).subscribe(
            (data:any) =>{
                this.sourceTeam = data.data.SourceTeam
                this.Position = data.data.Position
                // console.log(data)
                this.competitions = data.data.Competition
                this.season = data.data.Season
            }
        )
        
	}

    ColumnVisiblity(key){
		if(!this.colmvis.includes(key)){          //checking weather array contain the id
			this.colmvis.push(key);               //adding to array because value doesnt exists
		}else{
			this.colmvis.splice(this.colmvis.indexOf(key), 1);  //deleting
		}
		localStorage.setItem('class' ,JSON.stringify(this.colmvis))

        this.getFinalReport(this.reportfor, this.name);
	}

    getFinalReport(type , name){
        
        this.http.get(`${environment.apiUrl}/report/${type}?page=${1}&limit=${this.itemsPerPage}
            &${type}=${name}&competition_name=${this.competitionName.value}&season=${this.seasonName.value}`).subscribe(
                (data:any) =>{

                    this.CreateStat(this.seasonName.value,this.competitionName.value, this.name, type )
                    var totalavg = data.data.reduce((accumulator, current) => accumulator + current['AVG-'], 0);
                    this.totalavg = (totalavg / data.data.length).toFixed(1)
                    var totalavgplus = data.data.reduce((accumulator, current) => accumulator + current['AVG+'], 0);
                    this.totalavgplus = (totalavgplus / data.data.length).toFixed(1)
                    var totalmin = data.data.reduce((accumulator, current) => accumulator + current['MIN'], 0);
                    this.totalmin = (totalmin / data.data.length).toFixed(1)
                    var totalCVG = data.data.reduce((accumulator, current) => accumulator + current['CVG'], 0);
                    this.totalCVG = (totalCVG / data.data.length).toFixed(1)

                    var totalCVM = data.data.reduce((accumulator, current) => accumulator + current['CVM'], 0);
                    this.totalCVM = (totalCVM / data.data.length).toFixed(1)

                    var totalfor = data.data.reduce((accumulator, current) => accumulator + current['For'], 0);
                    this.totalfor = (totalfor / data.data.length).toFixed(2)

                    var totalagain = data.data.reduce((accumulator, current) => accumulator + current['Against'], 0);
                    this.totalagain = (totalagain / data.data.length).toFixed(2)
        
                    var totalmargin = data.data.reduce((accumulator, current) => accumulator + current['Margin'], 0);
                    this.totalmargin = (totalmargin / data.data.length).toFixed(2)


                    this.reports =  data;

                    if(this.reportfor === 'team'){
                    this.reports.data.push({
                            "Round": '',
                            "Season": '',
                            "Competition Name": "",
                            "Result": "",
                            "Oponent": "",
                            "Turn Around": "",
                            "AVG+": this.totalavgplus,
                            "AVG-": this.totalavg,
                            "CVG": this.totalCVG,
                            "CVM": this.totalCVM,
                            "For": this.totalfor,
                            "Against":  this.totalagain,
                            "Margin": this.totalmargin
                        })
                    }else{
                        this.updateFormValue(name)
                        this.reports.data.push(
                            {
                                "Match Date": "",
                                "Round": "",
                                "Source Team": "",
                                "Oponent": "",
                                "Turn Around": "",
                                "Position": "",
                                "Game Position": "",
                                "Result": "",
                                "MIN": this.totalmin,
                                "AVG+": this.totalavgplus,
                                "AVG-": this.totalavg,
                                "CVG": this.totalCVG,
                                "CVM":  this.totalCVM,
                                "CPC": "",
                                "Neg Pm": ""
                            }
                        )
                    }
        
                    // console.log(this.reports);
                    this.NewData =[''];
                    data.data.forEach(element => {
                        this.NewData.push({
                            "y":element.CVG,
                            "Result" : element.Result
                        });
                        this.NewDataAVG=['']
                        this.NewDataAVG.push({
                            "y":element["AVG-"],
                            "Result" : element.Result
                        })
                        this.Oponent=[''];
                        this.Oponent.push(element.Oponent)
                    });
                    if(this.reports.data.length != 0){
                        this.nodata = false;
                        this.ChartService.CreateCharts(this.Oponent,this.NewData,this.NewDataAVG);
                    }else{
                        document.getElementById('chart').innerHTML = '';
                        this.nodata = true
                    }
                    
                    this.defaultColDef = { 
                        resizable: true , 
                        sortable: true ,
                        minWidth:100,
                    };
                    var columnDefss=[]
        
                    for(let x of Object.keys(this.reports.data[0])){
                        var left;
                        var getWidth = x.length * 10;
                        var hidecol;
                        if(JSON.parse(localStorage.getItem('class')).includes(x)){
                            hidecol= true;
                            setTimeout(() => {
                                document.getElementById(x.replace(/\s/g, '')).style.opacity ='.5';
                            }, 1000);
                        }else{
                            hidecol= false
                        }
                        if(x === 'Source Team'){
                            getWidth = 200
                        }
                        if(x === 'Position'){
                            getWidth = 150
                        }
                        if(x === 'Oponent'){
                            getWidth = 150
                        }
                        if(x === 'Turn Around'){
                            getWidth = 150
                        }
                        columnDefss.push(
                            {
                                headerName: x,
                                field: x,
                                width:getWidth,
                                hide:hidecol,
                                // pinned: left,
                            }
                        )
                        
                    }
                    
                    this.columnDefs= columnDefss;
                    this.rowData =this.reports.data
                    // console.log(this.columnDefs, columnDefss)
                }
        )
    }

    
    
	viewInfo(info) {
		this.modalService.open(info, { size: 'md' });
	}
    
	// PROFILE EDIT

    updateFormValue(name): void {
          this.accountService.getPlayer(name).subscribe(data => {
              this.player = data;
            });
      }



	
}
<div class="container-fluid mt-3">
    <div class="row" *ngIf="filters">
        <div class="col-sm-12">
            <h5 class="mb-2 row mr-2 ml-2 justify-content-between align-items-center">
                <small>View Report - <b class="color">{{filternewname}} </b></small>
                <div class="btn-wrap">
                    <a routerLink="/home" class="back-btn">
                        Back
                        <svg  viewBox="0 0 512 425.49" fill="#fff"><g transform="translate(0 -43.255)"><path d="M142.716,293.147l-94-107.6,94-107.6a20.926,20.926,0,1,0-31.522-27.532L5.169,171.782a20.915,20.915,0,0,0,0,27.532L111.195,320.686a20.928,20.928,0,0,0,31.521-27.539Z"/><path d="M359.93,164.619h-339a20.926,20.926,0,1,0,0,41.852h339a110.211,110.211,0,1,1,0,220.422H48.828a20.926,20.926,0,1,0,0,41.852h311.1c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619Z"/></g></svg>
                    </a>
                </div>
            </h5>
        </div>
        <div class="col-sm-3">
            <ng-select class="mr-2" style="width: 100%;" [items]="filters.data.Competition" [multiple]="false" bindLabel="value" placeholder="Select competition" [(ngModel)]="compname">
            </ng-select>
        </div>
        <div class="col-sm-2">
            <ng-select class="mr-2" style="width: 100%;" [items]="filters.data.Season" [multiple]="false" bindLabel="value" placeholder="Select competition" [(ngModel)]="season">
            </ng-select>
        </div>
        <div class="col-sm-3">
            <button class="btn-theme" (click)="getreport()">View Report</button>
        </div>
    </div>
</div>

<div class="container">
    <h5 class="mt-2 pt-2 mb-4 row mr-2 ml-2 justify-content-between align-items-center">
        <span>{{filternewname}} </span>
        <div class="btn-wrap">
            <a routerLink="/home" class="back-btn">
                Back
                <svg  viewBox="0 0 512 425.49" fill="#fff"><g transform="translate(0 -43.255)"><path d="M142.716,293.147l-94-107.6,94-107.6a20.926,20.926,0,1,0-31.522-27.532L5.169,171.782a20.915,20.915,0,0,0,0,27.532L111.195,320.686a20.928,20.928,0,0,0,31.521-27.539Z"/><path d="M359.93,164.619h-339a20.926,20.926,0,1,0,0,41.852h339a110.211,110.211,0,1,1,0,220.422H48.828a20.926,20.926,0,1,0,0,41.852h311.1c83.844,0,152.07-68.219,152.07-152.063S443.781,164.619,359.93,164.619Z"/></g></svg>
            </a>
        </div>
    </h5>
</div>

<ng-container>
    <div class="container-fluid mt-5">
        <div class="row align-items-start">
            <div class="col-sm-5">
                <div class="player-wrapper">
                    <img src="assets/avtar.jpg">
                    <!-- <div>
                        <b>{{playerName}}</b> <br>
                        <span>{{sourceteam}}</span> <br>
                        <small>{{position}}</small>
                    </div> -->
                </div>

                <div class="player-charts">
                    <div class="score-chart">
                        <div id="mat-chart"></div> <span>MAT</span> </div>
                    <div class="score-chart">
                        <div id="cvg-chart"></div> <span>CVG <br>
                        <!-- <small *ngIf="stats">Rank {{stats.data.cvg_rank}} of {{stats.data.total}}</small> -->
                    </span>
                    </div>
                    <div class="score-chart">
                        <div id="cvm-chart"></div> <span>CVM</span></div>
                    <div class="score-chart">
                        <div id="avg-chart"></div> <span>AVG-</span></div>
                </div>
            </div>
            <div class="col-sm-7">
                <div id="chart" style="position:relative;z-index:2;width: 100%; height: 375px;"></div>
            </div>
        </div>
    </div>
    <hr>
    <div class="container-fluid table-wrapper report-component" *ngIf="reports && reports.data.length !=0">

        <table class="table">
            <thead>
                <tr *ngFor="let res of reports.data|slice:0:1">
                    <th>Sr. no.</th>
                    <ng-container *ngFor="let data of res | keyvalue : returnZero">
                        <th [class]="data.key" [attr.data-name]="data.key">{{data.key}}</th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let player of reports.data ;let i =index">
                    <td>{{i + 1 }}</td>
                    <ng-container *ngFor="let data of player  | keyvalue : returnZero">
                        <td [class]="data.key" [attr.data-name]="data.key">
                            {{data.value}}
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>

    </div>
</ng-container>
<!-- 



<div class="container">
    <div class="row">
        <span *ngIf="filters" class="col-sm-3">
            <ng-select class="mr-2" style="width: 100%;" [items]="filters.data.Competition" 
                [multiple]="false"
                bindLabel="value"
                placeholder="Select competition"
                [(ngModel)]="compname">
            </ng-select>
        </span>
        <span *ngIf="filters" class="col-sm-3">
            <ng-select class="mr-2" style="width: 100%;" [items]="filters.data.Season" 
                [multiple]="false"
                bindLabel="value"
                placeholder="Select competition"
                [(ngModel)]="season">
            </ng-select>
        </span>

        <button (click)="getreport()" class="btn-theme">Get Report</button>
    </div>
</div>
<hr>
<div class="container" *ngIf="players && players.total !=0">
    <div *ngFor="let player of players.data ">
        <ul>
            <li *ngFor="let data of player | keyvalue : returnZero" class="{{data.key}}">
                <b>{{data.key}}</b> <span> {{data.value}}</span>
            </li>
        </ul>
    </div>
</div>



<div class="text-center mt-4" *ngIf="players && totalItems === 0 ">
    <svg width="100" viewBox="0 0 496 416"><g transform="translate(-8 -48)"><path d="M476,48H36A28.032,28.032,0,0,0,8,76V436a28.032,28.032,0,0,0,28,28H476a28.032,28.032,0,0,0,28-28V76A28.032,28.032,0,0,0,476,48ZM36,64H476a12.013,12.013,0,0,1,12,12V96H24V76A12.013,12.013,0,0,1,36,64ZM476,448H36a12.013,12.013,0,0,1-12-12V112H488V436a12.013,12.013,0,0,1-12,12Z" fill="#9eca01"/><path d="M40,88h8a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M72,88h8a8,8,0,0,0,0-16H72a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M104,88h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M136,200a80,80,0,1,0,80,80A80,80,0,0,0,136,200Zm0,144a64,64,0,1,1,64-64,64,64,0,0,1-64,64Z" fill="#ff7201"/><path d="M165.657,250.343a8,8,0,0,0-11.314,0L136,268.686l-18.343-18.343a8,8,0,1,0-11.314,11.314L124.686,280l-18.343,18.343a8,8,0,0,0,11.314,11.314L136,291.314l18.343,18.343a8,8,0,1,0,11.314-11.314L147.314,280l18.343-18.343a8,8,0,0,0,0-11.314Z" fill="#ff7201"/><path d="M448,232H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z" fill="#ffab07"/><path d="M256,272h72a8,8,0,0,0,0-16H256a8,8,0,0,0,0,16Z" fill="#9eca01"/><path d="M448,280H256a8,8,0,0,0,0,16H448a8,8,0,0,0,0-16Z" fill="#9eca01"/><path d="M352,304H256a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" fill="#9eca01"/><path d="M384,304h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z" fill="#ffab07"/><path d="M448,256H352a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" fill="#ffab07"/></g></svg>
    <h1 class="display-4 mt-4">No Data Found</h1>
</div>


<div class="container fluid">
    <player-chart></player-chart>
</div> -->
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import * as Highcharts2 from 'highcharts/highcharts.src';
import HighchartsMore from 'highcharts/highcharts-more.src';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts2);
HighchartsSolidGauge(Highcharts2);
interface HighchartsChartExt extends Highcharts.Chart {
    customText?: Highcharts.SVGElement;
}

@Injectable({ providedIn: 'root' })
export class ChartService {
    avrageNo = [];

    CreateCharts2(Oponent, NewData, NewDataAVG) {
        this.avrageNo = []; // Clear the avrageNo array
        console.log("OPPONENT:",Oponent);
        NewData.forEach((element) => {
            this.avrageNo.push(element.y);
        });
        Oponent.pop();

        const combinedArray = Oponent.map((op, index) => ({
            opponent: op,
            newData: NewData[index],
            newDataAvg: NewDataAVG[index]
        }));

        // Sort the combined array based on the numeric part of the opponent string
        combinedArray.sort((a, b) => {
            const numA = parseInt(a.opponent.match(/^\d+/)?.[0] || '0', 10);
            const numB = parseInt(b.opponent.match(/^\d+/)?.[0] || '0', 10);
            return numA - numB;
        });

        // Extract the sorted values
        Oponent = combinedArray.map(item => item.opponent);
        NewData = combinedArray.map(item => item.newData);
        NewDataAVG = combinedArray.map(item => item.newDataAvg);

        Oponent.sort((a, b) => {
            const numA = parseInt(a.match(/^\d+/)?.[0] || '0', 10);
            const numB = parseInt(b.match(/^\d+/)?.[0] || '0', 10);
            return numA - numB;
        });
        // Calculate the AVG CVG line value
        const sumCVG = this.avrageNo.reduce((a, b) => a + b, 0);
        var svtsgrLine =  parseFloat(this.avrageNo[this.avrageNo.length-1]);

        Highcharts.chart('chart', {
            chart: {
                type: 'spline',
                backgroundColor: '#fff',
                renderTo: 'container',
                events: {
                    render: function () {
                        const chart = this as HighchartsChartExt;
                        // Remove previous text to avoid duplicates
                        if (chart.customText) {
                            chart.customText.destroy();
                        }
                        // Add custom text in the top right corner
                        chart.customText = chart.renderer.text(
                            `Season Avg CVG   ${svtsgrLine.toFixed(0)}`,
                            chart.chartWidth - 160, // Adjust the x position
                            20 // Adjust the y position
                        )
                            .css({
                                color: 'blue',
                                fontSize: '12px',
                            })
                            .add();
                    }
                }
            },
            title: null,
            xAxis: {
                categories: Oponent, // Use the teams list as categories
                labels: {
                    style: {
                        fontSize: '9.5px' // Reduce font size
                    },
                    formatter: function() {
                        const label = this.value as string;
                        return label.length > 10 ? label.substring(0, 10) + '...' : label;
                    },
                    rotation: -30 // Slant the text labels
                    
                },
                plotLines: [{
                    color: 'lightgrey', // Line color
                    width: 1, // Line width
                    value: -0.5, // Position on the axis (adjusted for proper alignment)
                    dashStyle: 'Solid', // Solid line
                    zIndex: 5, // Ensure the line appears above other elements
                }]
            },
            yAxis: {
                title: null,
                gridLineWidth: 0, // Remove horizontal grid lines
                minorGridLineWidth: 0, // Remove minor horizontal grid lines
                min: -400, // Set the minimum value for y-axis
                max: 1400, // Set the maximum value for y-axis
                tickInterval: 200, // Set the interval between tick marks to 700

                // startOnTick: false,
                // endOnTick: false,
                plotLines: [
                    {
                        color: 'blue', // Color value
                        value: this.avrageNo[this.avrageNo.length-1], // Value of where the line will appear
                        width: 2, // Width of the line
                        dashStyle: 'Dash',
                    },
                    {
                        color: 'lightgrey', // Line color
                        width: 1, // Line width
                        value: 0, // Position on the axis
                        dashStyle: 'Solid', // Solid line
                        zIndex: 5, // Ensure the line appears above other elements
                    },
                ],
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    enableMouseTracking: true,
                    marker: {
                        lineColor: '#9ECA01',
                    },
                },
            },
            series: [
                {
                    type: 'spline',
                    name: 'CVG ',
                    data: NewData,
                    pointStart: 0,
                    maxPointWidth: 20,
                    groupPadding: 0.2,
                    dataLabels: {
                        enabled: true,
                        format: `<p style="text-align:center">{point.y} <br> {point.Result}</p>`,
                        useHTML: true,
                    },
                    animation: false,
                    marker: {
                        symbol: 'circle',
                    },
                    color: '#9ECA01',
                },
                {
                    type: 'column',
                    name: 'NEGS ',
                    maxPointWidth: 20,
                    data: NewDataAVG,
                    groupPadding: 0.2,
                    dataLabels: {
                        y: 0,
                        enabled: true,
                        format: `<p style="text-align:center">{point.y}</p>`,
                        useHTML: true,
                    },
                    pointStart: 0,
                    marker: {
                        symbol: 'circle',
                    },
                    color: '#ff7f7f',
                },
                {
                    type: 'line',
                    name: 'AVG CVG',
                    data: this.avrageNo[this.avrageNo.length-1],
                    color: 'blue',
                    dashStyle: 'Dash',
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: true,
                    showInLegend: true,
                }
            ],
            tooltip: {
                enabled: false,
            },
        });
        
    }
    

	CaculatedReportCharts2(category, Chartdata) {
		//    console.log('c' ,category,'d', Chartdata)

		Highcharts.chart('statchart', {
			chart: {
				type: 'bar',
				backgroundColor: '#fff',
				renderTo: 'container',
			},
			title: null,
			xAxis: {
				categories: category,
			},
			yAxis: {
				min: 0,
				title: {
					text: null,
					align: 'high',
				},
				labels: {
					overflow: 'justify',
				},
			},
			plotOptions: {
				bar: {
					dataLabels: {
						enabled: true,
					},
				},
			},

			series: [
				{
					type: 'bar',
					name: 'CVG ',
					data: Chartdata,
				},
			],
		});
	}



    CreateCharts(Oponent, NewData, NewDataAVG) {
		NewData.forEach((element) => {
			this.avrageNo.push(element.y);
		});

		var svtsgrLine =
			this.avrageNo.reduce((a, b) => a + b, 0) / this.avrageNo.length;

		Highcharts.chart('chart', {
			chart: {
				type: 'spline',
				backgroundColor: '#fff',
				renderTo: 'container',
			},
			title: null,
			xAxis: {
				categories: Oponent,
			},
			yAxis: {
				title: null,

				plotLines: [
					{
						color: 'blue', // Color value
						value: Math.round(svtsgrLine), // Value of where the line will appear
						width: 1, // Width of the line
						dashStyle: 'Dash',
						label: {
							text: `<span class="badge badge-primary p-2 mt-3">AVGCVG, ${Math.round(
								svtsgrLine
							)}</span>`,
							useHTML: true,
							align: 'right',
							x: 0,
						},
					},
				],
			},
			plotOptions: {
				series: {
					cursor: 'pointer',
					enableMouseTracking: true,
					marker: {
						lineColor: '#9ECA01',
					},
				},
			},

			series: [
				{
					type: 'spline',
					name: 'CVG ',
					data: NewData,
					pointStart: 0,
					maxPointWidth: 20,
					groupPadding: 0.2,

					dataLabels: {
						enabled: true,
						format: `<p style="text-align:center">{point.y} <br> {point.Result}</p>`,
						useHTML: true,
						// rotation: 270,
					},

					animation: false,
					marker: {
						symbol: 'circle',
					},
					color: '#9ECA01',
				},
				{
					type: 'column',
					name: 'AVG ',
					maxPointWidth: 20,
					data: NewDataAVG,
					groupPadding: 0.2,
					dataLabels: {
						y: 0,
						enabled: true,
						format: `<p style="text-align:center">{point.y}</p>`,
						useHTML: true,
						// rotation: 270,
					},
					pointStart: 0,
					marker: {
						symbol: 'circle',
					},
					color: 'red',
				},
			],
			tooltip: {
				enabled: false,
			},
		});
	}

	CaculatedReportCharts(category, Chartdata) {
		//    console.log('c' ,category,'d', Chartdata)

		Highcharts.chart('statchart', {
			chart: {
				type: 'bar',
				backgroundColor: '#fff',
				renderTo: 'container',
			},
			title: null,
			xAxis: {
				categories: category,
			},
			yAxis: {
				min: 0,
				title: {
					text: null,
					align: 'high',
				},
				labels: {
					overflow: 'justify',
				},
			},
			plotOptions: {
				bar: {
					dataLabels: {
						enabled: true,
					},
				},
			},

			series: [
				{
					type: 'bar',
					name: 'CVG ',
					data: Chartdata,
				},
			],
		});
	}

	/*

    gaugeOptions = {
        chart: {
            type: 'solidgauge',
            backgroundColor: "#fff"
        },
        title: null,
        pane: {
            center: ['50%', '160px'],
            size: '100px',
            startAngle: -90,
            endAngle: 90,
        },

        background: {
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        },
        exporting: {enabled: false },
        tooltip: { enabled: false},
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 0,
                    borderWidth: 0,
                    useHTML: true,
                }
            }
        },
    }

    MAT(maxvalue, data){
        Highcharts.chart('mat-chart',  Highcharts.merge(this.gaugeOptions,{
            pane: {
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            yAxis: {
                min: 0,
                max: maxvalue,
                stops: [
                    [0.1, '#DF5353'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#55BF3B'] // red
                ],
                tickWidth: 1,
                minorTickInterval: null,
                tickAmount: 2,
            },
            series: [{
                type: 'solidgauge',
                data: [data],
            }],

        }))
    }

    CVG(maxvalue, data){
        Highcharts.chart('cvg-chart',  Highcharts.merge(this.gaugeOptions,{
            pane: {
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            yAxis: {
                min: 0,
                max: maxvalue,
                stops: [
                    [0.1, '#DF5353'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#55BF3B'] // red
                ],
                lineWidth: 1,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
            },
            series: [{
                type: 'solidgauge',
                data: [data],
            }],

        }))
    }

    CVM(maxvalue, data){
        Highcharts.chart('cvm-chart',  Highcharts.merge(this.gaugeOptions,{
            pane: {
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            yAxis: {
                min: 0,
                max: maxvalue,
                stops: [
                    [0.1, '#DF5353'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#55BF3B'] // red
                ],
                lineWidth: 1,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
            },
            series: [{
                type: 'solidgauge',
                data: [data],
            }],

        }))
    }
    AVG(maxvalue, data){
        // AVG- data
        Highcharts.chart('avg-chart',  Highcharts.merge(this.gaugeOptions,{
            pane: {
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            yAxis: {
                min: data,
                max: maxvalue,
                stops: [
                    [0.1, '#DF5353'], // green
                    [0.5, '#DDDF0D'], // yellow
                    [0.9, '#55BF3B'] // red
                ],
                lineWidth: 1,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
            },
            series: [{
                type: 'solidgauge',
                data: [data],
            }],

        }))
    }
*/
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '@app/_services';

@Component({ 
    templateUrl: 'add-edit.component.html',
    styleUrls: ['add-edit.component.scss'] 
 })
export class AddEditComponent implements OnInit {
    form: FormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    userroles = null;
    userdata: any
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) { this.route.params.subscribe(params => this.userdata = params.id)}

    ngOnInit() {
        this.accountService.getAllRoles().pipe(first())
        .subscribe(userroles => this.userroles = userroles);

        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;
        
        // password not required in edit mode
        const passwordValidators = [Validators.minLength(6)];
        if (this.isAddMode) {
            passwordValidators.push(Validators.required);
        }

        this.form = this.formBuilder.group({
            firstname: ['', Validators.required],
            lastname: ['', Validators.required],
            username: ['', Validators.required],
            email: ['', Validators.required],
            roles: ['', Validators.required],
            password: ['', passwordValidators]
        });

        if (!this.isAddMode) {
            this.accountService.getById(this.id).subscribe(
                data => {this.userdata = data;
                    // console.log('user' , this.userdata)
                    this.f.firstname.setValue(this.userdata.data.firstname);
                    this.f.lastname.setValue(this.userdata.data.lastname);
                    this.f.username.setValue(this.userdata.data.username);
                    this.f.email.setValue(this.userdata.data.email);
                    this.f.roles.setValue(this.userdata.data.roles.id);
                }
            )
        }
    }


    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.createUser();
        } else {
            this.updateUser();
        }
    }

    private createUser() {
        this.accountService.register(this.form.value).pipe(first())
        .subscribe(
            data => {
                this.alertService.success('User added successfully', { keepAfterRouteChange: true });
                this.router.navigate(['.', { relativeTo: this.route }]);
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }

    private updateUser() {
        this.accountService.update(this.id, this.form.value).pipe(first())
        .subscribe(
            data => {
                this.alertService.success('Update successful', { keepAfterRouteChange: true });
                this.router.navigate(['..', { relativeTo: this.route }]);
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            }
        );
    }
}
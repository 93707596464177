import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService, FileService } from '@app/_services';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { xml2js } from 'xml-js';

@Component({
	templateUrl: 'add-file.component.html',
	styleUrls: ['add-file.component.scss'],
})
export class AddDataFileComponent implements OnInit {
	@ViewChild('resumeInput', { static: true }) resumeInput;
	selectedFile: File = null;
	imageUrl: string;
	fileToUpload: File = null;
	saveFileForm: any;
	lstFileDetails: any;
	loading = false;
	selected_tournament: string = '';

	tournaments = [
		'NRL',
		'Origin',
		'NSW Cup',
		'Jersey Flegg',
		'SG Ball Cup',
		'Harold Matthews Cup',
		'QLD Cup',
		'Hastings Deering Colts',
		'Mal Meninga Cup',
		'Cyril Connell Cup',
		'NRL Womens',
		'Super League',
	];

	//csv file
	csvRecords: any;
	header: boolean = true;

	constructor(
		private service: FileService,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private alertService: AlertService,
		private ngxCsvParser: NgxCsvParser
	) {
		this.route.params.subscribe();
	}

	ngOnInit() {
		this.saveFileForm = this.formBuilder.group({
			file: null,
		});
	}

	onChange(value: any) {
		debugger;
		this.selected_tournament = value.target.value.split(': ')[1];
	}

	//csv file
	obj = {};
	AddCompetitionName(data: any[]) {
		debugger;
		data.forEach((element) => {
			debugger;
			if (element['Competition Name']?.length > 0) {
				element['Competition Name'] = this.selected_tournament;
			}
		});
		return data;
	}
	extractCsv(files: File[], i: number): void {
		if (this.selected_tournament.length == 0) {
			this.alertService.error('Please select tournament', {
				keepAfterRouteChange: true,
			});
			return;
		}
		this.ngxCsvParser
			.parse(files[i], {
				header: this.header,
				delimiter: ',',
			})
			.pipe()
			.subscribe({
				next: (_): void => {
					var formatted = this.AddCompetitionName(_ as any[]);

					this.service
					.addPlayer(_, this.selected_tournament)
					.subscribe((res) => {
						if (files.length == i + 1) {
							this.alertService.success(
								'File Uploaded successfull',
								{
									keepAfterRouteChange: true,
								}
							);
							this.router.navigate(['/competitions']);
						}
					});
					// }
				},
				error: (error: NgxCSVParserError): void => {
					console.log('Error', error);
				},
			});
	}

	extractXml(files: File[], i: number): void {
		if (this.selected_tournament.length == 0) {
			this.alertService.error('Please select tournament', {
				keepAfterRouteChange: true,
			});
			return;
		}
		this.service
			.addPlayerXml(files[i], this.selected_tournament)
			.subscribe((res) => {
				if (files.length == i + 1) {
					this.alertService.success('File Uploaded successful', {
						keepAfterRouteChange: true,
					});
					this.router.navigate(['/competitions']);
				}
			});
	}

	onExpSubmit() {
		if (this.saveFileForm.invalid) {
			return;
		}

		let formData = new FormData();
		for (let i = 0; i < this.resumeInput.nativeElement.files.length; i++) {
			const file = this.resumeInput.nativeElement.files[i];
			formData.append('file', file);

			if (file.type === 'text/csv') {
				this.extractCsv(this.resumeInput.nativeElement.files, i);
			} else if (
				file.type === 'text/xml' ||
				file.type === 'application/xml'
			) {
				this.extractXml(this.resumeInput.nativeElement.files, i);
			} else {
				this.alertService.error('Unsupported file type', {
					keepAfterRouteChange: true,
				});
			}
		}
		
	}
}

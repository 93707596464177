import { Component, OnInit } from '@angular/core';
import { AccountService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-assigncompetitions',
	templateUrl: './assign-competitions.component.html',
	styleUrls: ['./assign-competitions.component.scss'],
})
export class AssignCompetitionsComponent implements OnInit {
	allusers: any;
	users = [
		{
			id: 'all',
			name: 'All User',
			roles: '',
		},
	];
	Newusers: any;
	filters: any;
	newfilters = [
		{ filter_type: 'all', name: 'Competition', value: 'All Competition' },
	];
	competitionName: any;
	Newcompetition = [];
	username: any = [];
	restrictions: any;
	constructor(
		private accountService: AccountService,
		private alertService: AlertService
	) {}

	selection = false;
	ngOnInit() {
		this.accountService
			.getAll()
			.pipe(first())
			.subscribe((allusers) => {
				this.allusers = allusers;

				this.allusers.data.map((data) => {
					this.users.push({
						id: data.id,
						name: data.firstname + ' ' + data.lastname,
						roles: data.roles.name,
					});
				});
				var admin = this.users.indexOf({
					id: '',
					name: '',
					roles: 'admin',
				});
				this.users.splice(admin);
				// console.log(this.users)
			});
		this.accountService
			.getPlayersFilter('')
			.pipe(first())
			.subscribe((filters) => {
				this.filters = filters;
				// debugger;
				this.filters.Competition.map((data) => {
					if (data.filter_type && data.name && data.value) { // Check for non-empty and non-null fields
						this.newfilters.push({
							filter_type: data.filter_type,
							name: data.name,
							value: data.value,
						});
					}
				});

				console.log("New Filters:",this.newfilters);
			});
		this.getRestriction();
	}

	getallcompetition() {
		debugger;
		this.Newcompetition = [];
		var found = false;
		for (var i = 0; i < this.competitionName.length; i++) {
			if (this.competitionName[i].filter_type == 'all') {
				found = true;
				break;
			}
		}
		if (found === true) {
			for (let data of this.newfilters) {
				this.competitionName = [data.value];
				break;
			}
			this.filters.data.Competition.map((data) => {
				this.Newcompetition.push(data.value);
			});

			var Newcompetition = this.Newcompetition.indexOf('All Competition');
			this.Newcompetition.splice(Newcompetition, 0);
		} else {
			this.competitionName.map((data) => {
				this.Newcompetition.push(data.value);
			});
		}
	}
	getalluser() {
		this.Newusers = [];
		var found = false;
		for (var i = 0; i < this.username.length; i++) {
			if (this.username[i].id == 'all') {
				found = true;
				break;
			}
		}
		if (found === true) {
			for (let data of this.users) {
				this.username = [data.name];
				break;
			}
			this.allusers.data.map((data) => {
				this.Newusers.push(data.id);
			});

			var Newusers = this.Newusers.indexOf('All Users');
			this.Newusers.splice(Newusers, 0);
		} else {
			this.username.map((data) => {
				this.Newusers.push(data.id);
			});
		}
	}

	assignCompition() {
		var data = {
			competition_name: this.Newcompetition,
			user_id: this.Newusers,
		};
		console.log(data);
		this.accountService
			.AddRestriction(data)
			.pipe(first())
			.subscribe(
				(data) => {
					this.alertService.success('Assigned successfully', {
						keepAfterRouteChange: true,
					});
					this.getRestriction();
				},
				(error) => {
					this.alertService.error(error);
				}
			);
	}

	getRestriction() {
		this.accountService
			.getRestriction()
			.pipe(first())
			.subscribe((restrictions) => {
				this.restrictions = restrictions;
			});
	}
	deleteUser(id): void {
		if (confirm('Are you sure you want to delete ?')) {
			this.accountService.DeleteRestriction(id).subscribe(
				(data) => {
					this.alertService.success('User Deleted successfully', {
						keepAfterRouteChange: true,
					});
					this.getRestriction();
				},
				(error) => {
					this.alertService.error(error);
				}
			);
		}
	}
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AccountService, CSVService, ExcelService, PDFervice } from '@app/_services';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-download',
	templateUrl: './download.component.html',
	styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

	constructor(
		private http: HttpClient,
		private accountService: AccountService,
		private PDF: PDFervice,
		private xls: ExcelService,
		private CSV: CSVService,
	) { }

	tablehead:any;
	reports:any;
	competitions:any;
	competitionsName:any=[];
	
	competition_name:any;
	itemsPerPage:any;
	totalItems:any;
	filters:any;
	p:number= 1;
	selection = [];

	sortingname:string = "CVG"
	ASC:number= -1;
	groupName:any;

	downloadOption:any=[];
	NewData:any=[];
	createTable:any=[]
	ngOnInit(): void {
		this.itemsPerPage ="";
		this.accountService.getCompetitions().pipe(first()).subscribe(competitions => {
            this.competitions = competitions
			
			for(let x of this.competitions.data.competitions){
				x.map(data=>{
					this.competitionsName.push(data.value)
				})
			}
        })

	}
	returnZero() {return 0}

	groupBy(arr, property) {
		return arr.reduce(function(memo, x) {
		  	if (!memo[x[property]]) { memo[x[property]] = []; }
			memo[x[property]].push(x);
		  	return memo;
		}, {});
	}


	getData(page){
		this.accountService.getPlayersFilter(this.competition_name).pipe(first()).subscribe(filters =>{this.filters = filters});
		this.http.get(`${environment.apiUrl}/calculate/player/?page=${page}&limit=${this.itemsPerPage}
		&sortingname=${this.sortingname}&competition_name=${this.competition_name}&order=${this.ASC}`,).pipe(first())
		.subscribe((data: any)  => {
			this.reports =  data;
			this.totalItems = data.total;
		})
	}
	getSelectedData(page){
		var newFilter="";
		this.groupName=	this.groupBy(this.selection , 'filter_type')
		if(Object.keys(this.groupName).length > 0){
			for (let keys of Object.keys(this.groupName)) {
				let group_by_list = this.groupName[keys];
				newFilter+="&"+group_by_list[0].filter_type+"=";
				group_by_list.forEach(function (value) {
					newFilter+=value.value+",";
				}); 
			}
		}

		this.http.get(`${environment.apiUrl}/calculate/player/?page=${page}&limit=${this.itemsPerPage}
		${newFilter}&sortingname=${this.sortingname}&competition_name=${this.competition_name}&order=${this.ASC}`,).pipe(first())
		// this.http.get(`https://api-ms-v1.herokuapp.com/api/calculate/player/?page=1&limit=10&sortingname=CVG&competition_name=NRL%20Telstra%20Premiership&order=-1`,).pipe(first())
		.subscribe((data: any)  => {
			this.reports =  data;
			this.totalItems = data.total;
			this.createTable =[]
			this.downloadOption =[]
		})
	}

	changeHandler(flname, event: KeyboardEvent) {
		const value = flname.value;
		const index = this.selection.findIndex(u => u.value === value && u.filter_type===flname.filter_type);
		if (index === -1) {// ADD TO SELECTION
			this.selection = [...this.selection, flname];
		} else {// REMOVE FROM SELECTION
		  this.selection = this.selection.filter(user => user.value !== flname.value)
		}
		this.getSelectedData(1)
	}

	getSelection(flname) {
		return this.selection.findIndex(s => s.value === flname.value ) !== -1;
	}

	createJson(keys){
		if(!this.downloadOption.includes(keys)){          //checking weather array contain the id
			this.downloadOption.push(keys);               //adding to array because value doesnt exists
		}else{
			this.downloadOption.splice(this.downloadOption.indexOf(keys), 1);  //deleting
		}

		this.createTable =[]
		this.reports.data.map(x=>{
			var temp={};
			Object.keys(x).map(p=>{
				if(p != "Rank"){
				if(this.downloadOption.includes(p)){
					temp[p] = x[p]
				}}
			})
			this.createTable.push(temp);
		})
		
	}

	removeItem(i){
		this.createTable.splice(i,1);
	}
	
	savepdf(){
		this.PDF.convert(this.competition_name, this.createTable , this.downloadOption	)
	}

	savexls():void{
		this.xls.exportAsExcelFile(this.createTable,this.downloadOption, this.competition_name)
	}
	
	savCSV():void{
		var newdata= []
		this.reports.data.map(x=>{
			var temp={};
			Object.keys(x).map(p=>{
				if(this.downloadOption.includes(p)){
					temp[p] = x[p]
				}
			})
			newdata.push(temp);
		})
		this.CSV.downloadFile(newdata,this.competition_name, this.downloadOption)
	}
}


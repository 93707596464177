import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountService, AlertService, ChartService, CSVService, FileService } from '@app/_services';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
// import * as Highcharts from 'highcharts';
import { Subject } from 'rxjs';
@Component({ 
    selector: 'player-report',
    templateUrl: 'report.component.html',
	styleUrls: ['report.component.scss'] ,
})
export class TeamReportsComponent implements  OnInit {
    form: FormGroup;
    playersData = null;
	
	itemsPerPage:any;
	totalItems: any;
	items:any;
    
	filternewname:any;
	viewplayer:any;
    reports:any;

    playerName:any;
    competitions:any;
    filters:any;

    // CREATE FILTER 
    competitionName:any;
    seasonName:any;
    playerFilterName:any;

    NewData:any = []
    NewDataAVG:any = []
    Oponent:any = []
// NEW ARRAY
    season:any = [];
    competition:any = [];
    position:any;
    sourceteam:any;
    totalfor:any;
    totalagain:any;
    totalmargin:any;
    totalavg:any;
    totalavgplus:any;
    totalCVG:any;
    totalCVM:any;
    columnDefs: any;
	rowData: [];
	defaultColDef:any;
	player:any;
    colmvis=[];
    columnOption=[];
	constructor(
        private formBuilder: FormBuilder,
		private accountService: AccountService, 
        private route: ActivatedRoute,
        private router: Router,
        private service: FileService,
        private alertService: AlertService,
		private http: HttpClient,
        private ChartService: ChartService,
        private CSV: CSVService
    ) { 
        this.route.params.subscribe(params => this.playersData = params.Players)
    }
	ngOnInit(): void {
		this.itemsPerPage = ''

        this.competitionName = this.route.snapshot.params['name'].split('&')[0].replace(/-/g, ' ')
        this.season = this.route.snapshot.params['name'].split('&')[1].replace(/-/g, ' ')
        this.sourceteam = this.route.snapshot.params['name'].split('&')[2].replace(/-/g, ' ')
        this.getReport(this.sourceteam, this.competitionName, this.season);

        if(localStorage.getItem('class') != null && localStorage.getItem('class') != undefined){
			this.colmvis = JSON.parse(localStorage.getItem('class'))
		}else{
			this.colmvis = [];
		}
        
	}

	returnZero() {return 0}


    getPlayerName(){
        this.accountService.getPlayersNames().subscribe(playerName =>{this.playerName = playerName})
    }

    getCompetitionsName(){
        this.accountService.getCompetitions().subscribe(competitions => {this.competitions = competitions})
    }

    getFilter(){
        this.accountService.getPlayersFilter('').subscribe(filters =>{this.filters = filters
            for(let newseason of this.filters.data.Season){
                this.season = [newseason.value] 
            }
            for(let newseason of this.filters.data.Competition){
                this.competition = [newseason.value] 
            }
        });
    }
    ColumnVisiblity(key){
		if(!this.colmvis.includes(key)){          //checking weather array contain the id
			this.colmvis.push(key);               //adding to array because value doesnt exists
		}else{
			this.colmvis.splice(this.colmvis.indexOf(key), 1);  //deleting
		}
		localStorage.setItem('class' ,JSON.stringify(this.colmvis))
        this.getReport(this.sourceteam, this.competitionName, this.season)
	}

	getReport(sourceteam, competitionName, season){

        this.NewData= [];
        this.NewDataAVG = []
        this.Oponent = []
		this.http.get(`${environment.apiUrl}/report/team?page=${1}&limit=${this.itemsPerPage}
        &team=${sourceteam}&competition_name=${competitionName}&season=${season}
        `,)
		.subscribe((data: any)  => {this.reports =  data;

            console.log(this.reports.data)

            var totalavg = data.data.reduce((accumulator, current) => accumulator + current['AVG-'], 0);
            this.totalavg = (totalavg / data.data.length).toFixed(2)

            var totalavgplus = data.data.reduce((accumulator, current) => accumulator + current['AVG+'], 0);
            this.totalavgplus = (totalavgplus / data.data.length).toFixed(2)

            var totalfor = data.data.reduce((accumulator, current) => accumulator + current['For'], 0);
            this.totalfor = (totalfor / data.data.length).toFixed(2)

            var totalCVG = data.data.reduce((accumulator, current) => accumulator + current['CVG'], 0);
            this.totalCVG = (totalCVG / data.data.length).toFixed(2)

            var totalCVM = data.data.reduce((accumulator, current) => accumulator + current['CVM'], 0);
            this.totalCVM = (totalCVM / data.data.length).toFixed(1)

            var totalagain = data.data.reduce((accumulator, current) => accumulator + current['Against'], 0);
            this.totalagain = (totalagain / data.data.length).toFixed(2)

            var totalmargin = data.data.reduce((accumulator, current) => accumulator + current['Margin'], 0);
            this.totalmargin = (totalmargin / data.data.length).toFixed(2)

            this.reports.data.push(
                {
                    "Round": '',
                    "Season": '',
                    "Competition Name": "",
                    "Result": "",
                    "Oponent": "",
                    "Turn Around": "",
                    "AVG+": this.totalavgplus,
                    "AVG-": this.totalavg,
                    "CVG": this.totalCVG,
                    "CVM": this.totalCVM,
                    "For": this.totalfor,
                    "Against": this.totalagain,
                    "Margin": this.totalmargin
                }
            )



            data.data.forEach(element => {
                this.NewData.push({
                    "y":element.CVG,
                    "Result" : element.Result
                });
                this.NewDataAVG.push({
                    "y":element["AVG-"],
                    "Result" : element.Result
                })
                this.Oponent.push(element.Round +' ' + element.Oponent)
            });
            this.ChartService.CreateCharts(this.Oponent,this.NewData,this.NewDataAVG)

            this.defaultColDef = { 
				resizable: true , 
				sortable: true ,
				minWidth:100,
			};
			var columnDefss=[]

            for(let x of Object.keys(this.reports.data[0])){
				var left;
				var getWidth = x.length * 10;
				var hidecol;
                
                if(JSON.parse(localStorage.getItem('class')).includes(x)){
                    hidecol= true;
                    setTimeout(() => {
                        document.getElementById(x.replace(/\s/g, '')).style.opacity ='.5';
                    }, 1000);
                }else{
                    hidecol= false
                }
				if(x === 'Source Team'){
					getWidth = 200
				}
				if(x === 'Position'){
					getWidth = 150
				}
				if(x === 'Oponent'){
					getWidth = 150
				}
				if(x === 'Turn Around'){
					getWidth = 150
				}
				columnDefss.push(
					{
						headerName: x,
						field: x,
						width:getWidth,
						hide:hidecol,
						// pinned: left,
					}
				)
				
			}
			
			this.columnDefs= columnDefss;
			this.rowData =this.reports.data
		})
	}


    csv(){
		this.CSV.downloadFile(this.reports.data, this.playerName, Object.keys(this.reports.data[0]))
	}	
}
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User, Role } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AccountService {
	private userSubject: BehaviorSubject<User>;
	public user: Observable<User>;
	public Role: Observable<Role>;

	constructor(private router: Router, private http: HttpClient) {
		this.userSubject = new BehaviorSubject<User>(
			JSON.parse(localStorage.getItem('sportsuser'))
		);
		this.user = this.userSubject.asObservable();
	}

	public get userValue(): User {
		return this.userSubject.value;
	}

	login(username, password) {
		return this.http
			.post<User>(`${environment.apiUrl}/auth/signin`, {
				username,
				password,
			})
			.pipe(
				map((sportsuser) => {
					localStorage.setItem(
						'sportsuser',
						JSON.stringify(sportsuser)
					);
					this.userSubject.next(sportsuser);
					return sportsuser;
				})
			);
	}

	logout() {
		// remove user from local storage and set current user to null
		localStorage.removeItem('sportsuser');
		this.userSubject.next(null);
		this.router.navigate(['/login']);
	}

	// USERS API
	register(user: User) {
		return this.http.post(`${environment.apiUrl}/auth/signup`, user);
	}
	getAll() {
		return this.http.get<User[]>(`${environment.apiUrl}/user/all`);
	}
	getById(id: string) {
		return this.http.get<User>(`${environment.apiUrl}/user/${id}`);
	}
	update(id, params) {
		return this.http.put(`${environment.apiUrl}/user/${id}`, params).pipe(
			map((x) => {
				// update stored user if the logged in user updated their own record
				if (id == this.userValue.id) {
					// update local storage
					const user = { ...this.userValue, ...params };
					localStorage.setItem('sportsuser', JSON.stringify(user));
					// publish updated user to subscribers
					this.userSubject.next(user);
				}
				return x;
			})
		);
	}

	getUserLogs() {
		return this.http.get(`${environment.apiUrl}/user/login/logs`);
	}

	delete(id: string) {
		return this.http.delete(`${environment.apiUrl}/user/${id}`).pipe(
			map((x) => {
				// auto logout if the logged in user deleted their own record
				if (id == this.userValue.id) {
					this.logout();
				}
				return x;
			})
		);
	}

	//competitions LIST
	getCompetitions() {
		return this.http.get(`${environment.apiUrl}/dashboad/competitions`);
	}

	getPlayersFilter(url) {
		return this.http.get(`${environment.apiUrl}/filter/players?${url}`);
	}
	getPlayersNames() {
		return this.http.get(`${environment.apiUrl}/filter/players/names`);
	}

	// TEAMS

	getTeamsNames() {
		return this.http.get(`${environment.apiUrl}/filter/teams/names`);
	}

	//ROLES
	getAllRoles() {
		return this.http.get<User[]>(`${environment.apiUrl}/role/all`);
	}
	AddRole(name: Role) {
		return this.http.post(`${environment.apiUrl}/role`, name);
	}
	getRole(id: string) {
		return this.http.get<Role>(`${environment.apiUrl}/role/${id}`);
	}
	updateRole(_id, params) {
		return this.http.put(`${environment.apiUrl}/role/${_id}`, params);
	}
	deleteRole(_id: string) {
		return this.http.delete(`${environment.apiUrl}/role/${_id}`);
	}

	// DATA
	getData() {
		return this.http.get<Role>(`${environment.apiUrl}/player`);
	}
	AddData(file: any) {
		return this.http.post(`${environment.apiUrl}/fileupload`, file);
	}

	deleteData() {
		return this.http.delete(`${environment.apiUrl}/player/drop`);
	}

	deleteCompitation(name) {
		return this.http.delete(
			`${environment.apiUrl}/player/competition/delete?competition_name=${name}`
		);
	}
	deleteRound(name, round) {
		return this.http.delete(
			`${environment.apiUrl}/player/competition/delete?competition_name=${name}&round=${round}`
		);
	}

	AddRestriction(data) {
		return this.http.post(`${environment.apiUrl}/restriction/add`, data);
	}
	getRestriction() {
		return this.http.get(`${environment.apiUrl}/restriction/all`);
	}
	DeleteRestriction(id) {
		return this.http.delete(`${environment.apiUrl}/restriction/${id}`);
	}

	updatePlayer(data) {
		return this.http.post(
			`${environment.apiUrl}/player/info/create/`,
			data
		);
	}
	getPlayer(name) {
		return this.http.get(
			`${environment.apiUrl}/player/info/single/${name}`
		);
	}
	deletePlayerFiltered(queryArr) {
		return this.http.delete(
			`${environment.apiUrl}/player/deleteFilteredPlayers?${queryArr}`
		);
	}
}

<div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      [style.width]="getPercentageWidth(value, maxValue)"
      [style.background]="getGradientStyle(fieldName)"
      [attr.aria-valuenow]="value"
      aria-valuemin="0"
      aria-valuemax="maxValue"
    ></div>
  </div>
  <span class="td-value">{{ value }}</span>
  
export class User {
    id: string;
    username: string;
    password: string;
    firstname: string;
    lastname: string;
    contact: number;
    roles: {
        id:string;
        name:string;
    }
    accessToken: string;
}
<div class="container-fluid table-wrapper">
	<div class="wrapper">
		<div class="col filtercol pt-2" *ngIf="competitions">
			<h6>Select Competition</h6>
			<ng-select
				style="width: 100%; margin-top: 10px"
				[items]="competitionsName"
				[multiple]="false"
				bindLabel="value"
				placeholder="Select Player"
				[(ngModel)]="competition_name"
				(ngModelChange)="getData(1)"
			>
			</ng-select>

			<div *ngIf="filters" class="plfilter">
				<div
					*ngFor="
						let filtername of filters.data | keyvalue : returnZero
					"
				>
					<div
						class="filters {{ filtername.key }}"
						*ngIf="filtername.key != 'Competition'"
					>
						<h6>
							<b>
								<i
									class="far fa-calendar-check"
									*ngIf="filtername.key === 'Season'"
								></i>
								<i
									class="far fa-flag"
									*ngIf="filtername.key === 'Round'"
								></i>
								<i
									class="fas fa-gamepad"
									*ngIf="filtername.key === 'Game Position'"
								></i>
								<i
									class="fas fa-users"
									*ngIf="filtername.key === 'Source Team'"
								></i>
								<i
									class="fas fa-crosshairs"
									*ngIf="filtername.key === 'Position'"
								></i>
								<i
									class="fas fa-clock"
									*ngIf="filtername.key === 'Minutes Played'"
								></i>
								{{ filtername.key }}
							</b>
						</h6>
						<hr />
						<ng-container
							*ngFor="
								let flname of filtername.value;
								let i = index
							"
						>
							<label *ngIf="flname.value" class="filtervalue">
								<input
									type="checkbox"
									(checked)="getSelection(flname)"
									(change)="changeHandler(flname, $event)"
								/>
								<div>{{ flname.value }}</div>
							</label>
						</ng-container>
					</div>
				</div>
			</div>
		</div>

		<div
			class="col col-table mt-2"
			style="height: calc(100vh - 100px) !important"
		>
			<div *ngIf="reports" style="position: sticky; left: 0">
				<button (click)="savepdf()" class="btn-theme mr-2">
					Download PDF
				</button>
				<button (click)="savexls()" class="btn-theme">
					Download Excel
				</button>
				<button (click)="savCSV()" class="btn-theme ml-2">
					Download CSV
				</button>
				<br /><br />
				<h6>Select Option For Download</h6>
				<ng-container
					*ngFor="
						let ttt of reports.data | slice : 0 : 1;
						let i = index
					"
				>
					<label
						*ngFor="let data of ttt | keyvalue : returnZero"
						class="mr-3"
					>
						<ng-container *ngIf="data.key != 'Rank'">
							<input
								type="checkbox"
								[value]="data.key"
								(click)="createJson(data.key)"
							/>
							{{ data.key }}
						</ng-container>
					</label>
				</ng-container>
			</div>
			<hr />
			<table class="table">
				<thead *ngIf="createTable">
					<tr
						*ngFor="
							let ttt of createTable | slice : 0 : 1;
							let i = index
						"
					>
						<th></th>
						<ng-container
							*ngFor="
								let data of ttt | keyvalue : returnZero;
								let i = index
							"
						>
							<th
								[attr.class]="data.key"
								[attr.data-name]="data.key"
							>
								{{ data.key }}
							</th>
						</ng-container>
					</tr>
				</thead>
				<tbody *ngIf="createTable">
					<tr
						*ngFor="
							let player of createTable
								| paginate
									: {
											itemsPerPage: itemsPerPage,
											currentPage: p,
											totalItems: totalItems
									  };
							let i = index
						"
					>
						<td (click)="removeItem(i)">
							<svg
								_ngcontent-srd-c49=""
								width="15.632"
								height="17.569"
								viewBox="0 0 15.632 17.569"
							>
								<g
									_ngcontent-srd-c49=""
									transform="translate(0.2 0.2)"
								>
									<g
										_ngcontent-srd-c49=""
										transform="translate(0 0)"
									>
										<path
											_ngcontent-srd-c49=""
											d="M72.6,114.908H61.461a.44.44,0,0,0-.33.11.374.374,0,0,0-.11.33l1.145,9.971a2.179,2.179,0,0,0,2.179,1.915h5.547a2.179,2.179,0,0,0,2.2-1.981l.946-9.949a.374.374,0,0,0-.11-.286A.44.44,0,0,0,72.6,114.908Zm-1.387,10.3a1.277,1.277,0,0,1-1.321,1.145H64.344a1.255,1.255,0,0,1-1.3-1.123l-1.1-9.443H72.114Z"
											transform="translate(-59.414 -110.065)"
											fill="#fc1919"
											stroke="red"
											stroke-width="0.4"
										></path>
										<path
											_ngcontent-srd-c49=""
											d="M37.784,1.981H33.25v-.7A1.233,1.233,0,0,0,32.063,0h-2.84a1.233,1.233,0,0,0-1.255,1.21q0,.033,0,.067v.7H23.433a.44.44,0,1,0,0,.88H37.784a.44.44,0,1,0,0-.88Zm-5.415-.7v.7H28.848v-.7a.352.352,0,0,1,.374-.4H32a.352.352,0,0,1,.376.326A.348.348,0,0,1,32.37,1.277Z"
											transform="translate(-22.993 0)"
											fill="#fc1919"
											stroke="red"
											stroke-width="0.4"
										></path>
									</g>
								</g>
							</svg>
						</td>
						<ng-container
							*ngFor="let data of player | keyvalue : returnZero"
						>
							<td
								[attr.class]="data.key"
								[attr.data-name]="data.key"
							>
								{{ data.value }}
							</td>
						</ng-container>
					</tr>
				</tbody>
			</table>
			<span
				*ngIf="reports"
				class="pageinationwrap"
				style="position: static"
			>
				<pagination-controls
					*ngIf="reports.pages != 1"
					(pageChange)="getData((p = $event))"
				></pagination-controls>
			</span>
		</div>
	</div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	url = `${environment.apiUrl}`;

	constructor(private router: Router, private http: HttpClient) {}
	public downloadFile(docFile: string): Observable<Blob> {
		return this.http.get(this.url + '/GetFile?docFile=' + docFile, {
			responseType: 'blob',
		});
	}
	public downloadImage(image: string): Observable<Blob> {
		return this.http.get(this.url + '/GetImage?image=' + image, {
			responseType: 'blob',
		});
	}
	public getFiles(): Observable<any[]> {
		return this.http.get<any[]>(this.url + '/GetFileDetails');
	}

	AddFileDetails(data: FormData): Observable<string> {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		const httpOptions = {
			headers: headers,
		};
		return this.http.post<string>(
			this.url + '/import/player',
			data,
			httpOptions
		);
	}

	addFileDetails(data: FormData) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		const httpOptions = {
			headers: headers,
		};
		return this.http.post(`${environment.imgupload}`, data, httpOptions);
	}
	
	addPlayer(data: any, selected_tournament: string) {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		const httpOptions = {
			headers: headers,
		};
		return this.http.post(
			`${environment.apiUrl}/player`,
			{ players: data, selected_tournament: selected_tournament },
			httpOptions
		);
	}

	addPlayerXml(file: File, selected_tournament: string) {
		let formData = new FormData();
		formData.append('file', file);
		formData.append('selected_tournament', selected_tournament);

		return this.http.post(`${environment.apiUrl}/playerXml`, formData);
	}

	getAllPlayers() {
		return this.http.get<[]>(`${environment.apiUrl}/player`);
	}

	getnext(currentpage: number) {
		// return this.http.get<[Listing]>(`${environment.apiUrl}/player/page/${currentpage}`);
		return this.http.get(
			`${environment.apiUrl}/player?page=${currentpage}`
		);
	}
}

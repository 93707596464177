// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// apiUrl: 'https://testapi.cvr-analysis.com/api',
	apiUrl: 'https://cvm-be.herokuapp.com/api',
	// apiUrl: 'http://localhost:8080/api',
	verUrl: 'https://api-ms-v1.herokuapp.com',
	imgupload: 'http://cvr-analysis.com/profile/save.php',
	imgPath: 'http://cvr-analysis.com/profile/player',
};

/*

 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
